import React, { useState } from "react";

import "./leftNav.scss";
import { NavLink, useLocation, Link } from "react-router-dom";

const LeftNav = ({ open, openLogoutBtn, closeLogoutBtn, openLogoutPage }) => {
  const location = useLocation();

  const { pathname } = location;

  const splitLocation = pathname.split("/");

  return (
    <>
      <div
        open={open}
        style={{
          transform: `${open ? "translateX(0)" : "translateX(100%)"}`,
          transition: "transform 0.3s ease-in-out",
        }}
        className="sidemenu-family-cont"
      >
        <div className="top-navbar-rel">
          <div className="cont-main">
            <Link
              to="/"
              className={
                splitLocation[1] === ""
                  ? "leftNavbar-opt-active"
                  : "leftNavbar-opt"
              }
              style={{ textDecoration: "none" }}
            >
              HOME
            </Link>

            <Link
              to="/services"
              className={
                splitLocation[1] === "services"
                  ? "leftNavbar-opt-active"
                  : "leftNavbar-opt"
              }
              style={{ textDecoration: "none" }}
            >
              SERVICES
            </Link>

            <Link
              to="/contact"
              className={
                splitLocation[1] === "contact"
                  ? "leftNavbar-opt-active"
                  : "leftNavbar-opt"
              }
              style={{ textDecoration: "none" }}
            >
              CONTACT
            </Link>
            {/* <Link
              to="/allproducts"
              className={
                splitLocation[1] === "allproducts"
                  ? "leftNavbar-opt-active"
                  : "leftNavbar-opt"
              }
              style={{ textDecoration: "none" }}
            >
              PRODUCTS
            </Link> */}
            {/* <Link
              to="/training"
              className={
                splitLocation[1] === "training"
                  ? "leftNavbar-opt-active"
                  : "leftNavbar-opt"
              }
              style={{ textDecoration: "none" }}
            >
              TRAINING CENTER
            </Link> */}

            <div
              // to="/contact_page"
              // className={
              //   splitLocation[1] === "contact_page"
              //     ? "leftNavbar-opt-active"
              //     : "leftNavbar-opt"
              // }
              style={{ textDecoration: "none", paddingBottom: "300px" }}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeftNav;
