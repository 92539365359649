import React from "react";
import "./sectionThree.scss";
import SectionThreeCard from "./sectionThreeCard/SectionThreeCard";
import {
  arrow_right_,
  section_three_card_rope,
  section_three_dog,
} from "../../../../assets";

const SectionThree = () => {
  return (
    <div className="main_home_sectionThree_gen_bodyy">
      <div className="main_home_sectionThree_gen_bodyy__sub">
        <div className="main_home_sectionOne_gen_bodyy__subH_text">
          Easy Steps To Register Your Microchipped Pet
        </div>

        <div className="main_home_sectionOne_gen_bodyy__subH_flex">
          <SectionThreeCard headText="Create An Account" />
          <div className="main_home_sectionOne_gen_bodyy__subH_rope">
            <img src={section_three_card_rope} />
          </div>
          <SectionThreeCard headText="Update Pet Information" />
          <div className="main_home_sectionOne_gen_bodyy__subH_rope">
            <img src={section_three_card_rope} />
          </div>
          <SectionThreeCard headText="Get Verified" />
        </div>

        <div className="main_home_sectionOne_gen_bodyy__substart_hebtnFam">
          <div className="main_home_sectionOne_gen_bodyy__substart_hebtn">
            <div className="main_home_sectionOne_gen_bodyy__substart_hetext">
              START HERE
            </div>
            <div className="main_home_sectionOne_gen_bodyy__substart_hearrow">
              <img src={arrow_right_} />
            </div>
          </div>
        </div>

        <div className="main_home_sectionOne_gen_bodyy__substart_dogggg">
          <img src={section_three_dog} />
        </div>
      </div>
    </div>
  );
};

export default SectionThree;
