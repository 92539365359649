import React from "react";
import "./sectionOne.scss";
import { partner_round } from "../../../../assets";

const SectionOne = () => {
  return (
    <div className="partner_main_home_micro_sectionOne_gen_bodyy">
      <div className="partner_main_home_micro_sectionOne_gen_bodyy__sub">
        <div className="partner_sectionOne_top_flexxBody">
          <div className="partner_sectionOne_top_flexxImg">
            <img src={partner_round} />
          </div>
          <div className="partner_sectionOne_top_flexxtext">
            Lorem ipsum dolor sit amet enim. Etiam ullamcorper. Suspendisse a
            pellentesque dui, non felis. Maecenas malesuada elit lectus felis,
            malesuada ultricies.{" "}
            <span>
              Lorem ipsum dolor sit amet enim. Etiam ullamcorper. Suspendisse a
              pellentesque dui, non felis. Maecenas malesuada elit lectus felis,
              malesuada ultricies.
            </span>
            <span>
              Lorem ipsum dolor sit amet enim. Etiam ullamcorper. Suspendisse a
              pellentesque dui, non felis. Maecenas malesuada elit lectus felis,
              malesuada ultricies.
            </span>
          </div>
          <div className="micro_sectionOne_top_flexxAbsolute">
            PARTNER <span style={{ color: "#EEC355" }}> WITH</span>
            <span style={{ color: "#E24F6D", marginLeft: "15px" }}>US </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionOne;
