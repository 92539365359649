import React from "react";
import Input from "../../../../components/form/input";
import "./index.scss";
import Sizebox from "../../../../components/sizebox";
import { Visibility } from "@mui/icons-material";

const VerifyEmailScreen = ({ handleChange, account, history, sendOtp }) => {
  return (
    <div className="petOwner">
      <Sizebox height="20px" />
      <Input
        onChange={handleChange}
        name="code"
        value={account?.code}
        placeholder={"737839"}
        type="code"
        label={"Please enter OTP"}
      />

      <div className="forgotPass">
        <p>
          didn't? get an OTP{" "}
          <span
            onClick={() => {
              sendOtp();
            }}
          >
            Resend OTP
          </span>
        </p>
      </div>
    </div>
  );
};

export default VerifyEmailScreen;
