import React from "react";
import "./sectionSix.scss";
import {
  arrow_right_,
  section_six_img,
  section_six_img_two,
  section_six_imgOne,
  section_six_imgTwo,
} from "../../../../assets";

const SectionSix = () => {
  return (
    <div className="main_home_sectionSix_gen_bodyy">
      <div className="main_home_sectionSix_gen_bodyy__sub">
        <div className="main_home_sectionSix_flexed">
          <div className="main_home_sectionSix_flexed_img">
            <img src={section_six_img} />
          </div>
          <div className="main_home_sectionSix_flexed_img">
            <img src={section_six_imgOne} />
          </div>
          <div className="main_home_sectionSix_flexed_img">
            <img src={section_six_imgTwo} />
          </div>
          <div className="main_home_sectionSix_flexed_img">
            <img src={section_six_img_two} />
          </div>
        </div>

        <div className="main_home_sectionSix_gen_reg_btn">
          <div className="main_home_sectionSix_gen_reg_btn_text">
            SHOP PET STORE
          </div>
          <div className="main_home_sectionSix_gen_reg_btn_arrowright">
            <img src={arrow_right_} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionSix;
