import React, { useState, useEffect } from "react";
import { Modal as ModalC } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import MaterialTable, { Column } from "material-table";
import { Paper } from "@material-ui/core";
import { NotificationManager } from "react-notifications";
// import "./index.css";
import { axiosCalls } from "../../../_api";
import icons from "../../../components/table/icons";
import { turnDate } from "../../../components/helper";
import Input from "../../../components/form/input";
import Sizebox from "../../../components/sizebox";
import FileUploader from "../../../components/form/fileUploader";
import SelectBox from "../../../components/form/select";
import { Button } from "@mui/material";
import { useHistory } from "react-router-dom";
import moment from "moment";
import GridTable from "../../../components/tableCom/table";

export default function VasinationTable({
  onOpenModal,
  onOpenModalTracks,
  data,
  deleteRole,
  reloadIsDelete,
  showModal,
  handleClose,
  mode,
  handleEdit,
  petInfo,
  handleChange,
  handleSubmit,
  handleActivationAdd,
  ids,
  path,
  reloadTable,
  setReloadTable,
}) {
  const history = useHistory();

  const [edit, setEdit] = useState({});
  const [roleId, setRoleId] = useState();
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteParam, setDeleteParam] = useState();

  const onOpenModalDelete = () => setOpenDelete(true);
  const onCloseModalDelete = () => setOpenDelete(false);

  const handleDelete = (data) => {
    onOpenModalDelete();
    setDeleteParam(data);
  };

  return (
    <div className="table-wrap border styleTable">
      <GridTable
        columns={[
          {
            title: "Vaccine Type",
            field: "vaccine",
          },
          { title: "Veterinarian name", field: "veterinarian_name" },

          {
            title: "Date vaccinated",
            field: "vaccine",
            render: (rowData) => (
              <span>
                {moment(rowData?.vaccination_date).format("DD-MM-YYYY")}
              </span>
            ),
          },

          {
            title: "Next Vaccination Date",
            field: "next_vaccination_date",
            render: (rowData) => (
              <span>
                {moment(rowData?.next_vaccination_date).format("DD-MM-YYYY")}
              </span>
            ),
          },

          {
            title: "Actions",
            field: "action",
            render: (rowData) => (
              <div>
                <span
                  style={{
                    cursor: "pointer",
                    marginRight: "20px",
                    color: "green",
                  }}
                  className="pending-action-table-btn"
                  onClick={() => handleEdit(rowData)}
                >
                  View
                </span>
              </div>
            ),
          },
        ]}
        path={path}
        reloadTable={reloadTable}
        setReload={setReloadTable}
        data={data}
        title="Vaccination"
      />
      {/* <MaterialTable
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        icons={icons}
        options={{
          exportButton: true,
          pageSize: 10,
          pageSizeOptions: [50, 100, 150],
        }}
        data={data}
        title="Vaccination"
      /> */}

      {ids.length > 0 ? (
        <div>
          <br />
          <button>Pay for pet{ids.length == 1 ? "" : "s"}</button>
        </div>
      ) : null}

      <ModalC open={showModal} onClose={handleClose} center>
        <div className="successModal">
          <h2>Vaccination Details</h2>

          <Sizebox height="20px" />

          <div className="Vaccination_details">
            <div>
              <p>Veterinarian Name</p>
            </div>
            <div>
              <p>{petInfo?.veterinarian_name}</p>
            </div>
          </div>

          <div className="Vaccination_details">
            <div>
              <p>Vaccination Type</p>
            </div>
            <div>
              <p>{petInfo?.vaccine}</p>
            </div>
          </div>

          <div className="Vaccination_details">
            <div>
              <p>Next Vaccination Date</p>
            </div>
            <div>
              <p>
                {" "}
                {moment(petInfo?.next_vaccination_date).format("DD-MM-YYYY")}
              </p>
            </div>
          </div>

          <div className="Vaccination_details">
            <div>
              <p>Next Vaccination Date</p>
            </div>
            <div>
              <p>
                {" "}
                {moment(petInfo?.next_vaccination_date).format("DD-MM-YYYY")}
              </p>
            </div>
          </div>

          <div className="Vaccination_details">
            <div>
              <p>Vaccine Expiry Date</p>
            </div>
            <div>
              <p>
                {" "}
                {moment(petInfo?.expiry_date ?? new Date()).format(
                  "DD-MM-YYYY"
                )}
              </p>
            </div>
          </div>

          <div className="Vaccination_details">
            <div>
              <p>Vaccine Number</p>
            </div>
            <div>
              <p>
                {" "}
                {moment(petInfo?.vcn_number ?? new Date()).format("DD-MM-YYYY")}
              </p>
            </div>
          </div>
        </div>
      </ModalC>

      <ModalC open={openDelete} onClose={onCloseModalDelete} center>
        <div className="deleteHeader">Delete Role?</div>
        <div className="deleteSubText">
          Deleting this role is irreversible do you want to continue?
        </div>

        <div
          onClick={() => {
            deleteRole(deleteParam);
            onCloseModalDelete();
            reloadIsDelete();
          }}
          className="deleteYesBtn"
        >
          Yes
        </div>
        <div className="deleteNoBtn" onClick={onCloseModalDelete}>
          No
        </div>
      </ModalC>
    </div>
  );
}
