import React from "react";
import "./blogPostCard.scss";
import { blog_post_img, left_big_arrow } from "../../../../assets";
import GradientBtn from "../../gradientBtn/GradientBtn";

const BlogPostCard = () => {
  return (
    <div className="blog_post_card_blog_sectionGen">
      <div className="blog_post_card_blog_sectionGen_img">
        <img src={blog_post_img} />
      </div>
      <div className="blog_post_card_blog_sectionGen_head">LOREM IPSUM</div>
      <div className="blog_post_card_blog_sectionGen_body">
        Lorem ipsum dolor sit amet enim. Etiam ullamcorper. Suspendisse a
        pellentesque dui, non felis. Maecenas malesuada elit lectus felis,
        malesuada ultricies.
      </div>

      <div className="blog_gradient_two_flex_left_search___">
        <div className="blog_gradient_sectionOne_top_flexx_left_bigbtn">
          READ MORE
        </div>
        <div className="blog_gradient_sectionOne_top_flexx_left_bigarrow">
          <img src={left_big_arrow} />
        </div>
      </div>
    </div>
  );
};

export default BlogPostCard;
