import React from "react";
import "./sectionOne.scss";
import { blog_header_gradient } from "../../../../assets";

const SectionOne = () => {
  return (
    <div className="main_home_support_sectionOne_gen_bodyy">
      <div className="main_home_support_sectionOne_gen_bodyy__sub">
        <div className="micro_sectionOne_top_flexxBody">
          <div className="micro_sectionOne_top_flexxImg">
            <img src={blog_header_gradient} />
          </div>
          <div className="support_sectionOne_top_flexxtext">
            Lorem ipsum dolor sit amet enim. Etiam ullamcorper. Suspendisse a
            pellentesque dui, non felis. Maecenas malesuada elit lectus felis,
            malesuada ultricies.{" "}
          </div>
          <div className="micro_sectionOne_top_flexxAbsolute">
            LATEST
            <span style={{ color: "#E24F6D", marginLeft: "15px" }}>NEWS </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionOne;
