import React from "react";
import "./sectionThreeCard.scss";
import { section_three_card_img } from "../../../../../assets";

const SectionThreeCard = ({ headText }) => {
  return (
    <div className="main_home_sectionThree_bodyGen">
      <div className="main_home_sectionThree_bodyIconGen">
        <div className="main_home_sectionThree_bodyIcon">
          <img src={section_three_card_img} />
        </div>
        <div className="main_home_sectionThree_bodyIcon_te">{headText}</div>
      </div>

      <div className="main_home_sectionThree_bodyIcon_textt">
        Lorem ipsum dolor sit amet enim. Etiam ullamcorper. Suspendisse a
        pellentesque dui, non felis. Maecenas malesuada elit lectus felis,
        malesuada ultricies.
      </div>
    </div>
  );
};

export default SectionThreeCard;
