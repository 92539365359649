import React, { useEffect } from "react";
import "./partnerWithUs.scss";
import Header from "../../../components/landingPage/header/Header";
import Footer from "../../../components/landingPage/footer/Footer";
import SectionOne from "../../../components/landingPage/partnerWithUs/sectionOne/SectionOne";
import SectionTwo from "../../../components/landingPage/partnerWithUs/sectionTwo/SectionTwo";
import { useLocation } from "react-router-dom";

const PartnerWithUs = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <div>
      <Header />
      <SectionOne />
      <SectionTwo />
      <Footer />
    </div>
  );
};

export default PartnerWithUs;
