import React from "react";
import "./sectionOne.scss";
import {
  cat_micro,
  micro_loca_icon,
  micro_section_heading,
  micro_top_arrow_btn,
} from "../../../../assets";

const SectionOne = () => {
  return (
    <div className="main_home_micro_sectionOne_gen_bodyy">
      <div className="main_home_micro_sectionOne_gen_bodyy__sub">
        <div className="micro_sectionOne_top_flexxBody">
          <div className="micro_sectionOne_top_flexxImg">
            <img src={micro_section_heading} />
          </div>
          <div className="micro_sectionOne_top_flexxtext">
            Lorem ipsum dolor sit amet enim. Etiam ullamcorper. Suspendisse a
            pellentesque dui, non felis. Maecenas malesuada elit lectus felis,
            malesuada ultricies.{" "}
            <span>
              Lorem ipsum dolor sit amet enim. Etiam ullamcorper. Suspendisse a
              pellentesque dui, non felis. Maecenas malesuada elit lectus felis,
              malesuada ultricies.
            </span>
          </div>
          <div className="micro_sectionOne_top_flexxAbsolute">
            MICROCHIP <span style={{ color: "#EEC355" }}> YOUR</span>
            <span style={{ color: "#E24F6D", marginLeft: "15px" }}>PET </span>
          </div>
        </div>

        <div className="micro_sectionOne_top_flexx_left_search__">
          <div className="section_two_flex_left_search_body_microchip">
            <div className="section_two_flex_left_search_icon_microchip">
              <img src={micro_loca_icon} />
            </div>
            <input
              type="text"
              placeholder="Enter Location"
              // value={searchText}
              // onChange={handleSearchChange}
            />
          </div>

          <div className="section_two_flex_left_search___">
            <div className="micro_sectionOne_top_flexx_left_bigbtn">
              FIND VET
            </div>
            <div className="micro_sectionOne_top_flexx_left_bigarrow">
              <img src={micro_top_arrow_btn} />
            </div>
          </div>
        </div>
      </div>
      <div className="micro_section_catt_imgg">
        <img src={cat_micro} />
      </div>
    </div>
  );
};

export default SectionOne;
