import React from "react";
import "./sectionSeven.scss";
import { arrow_right_, dog_section_seven } from "../../../../assets";

const SectionSeven = () => {
  return (
    <div className="main_home_sectionSeven_gen_bodyy">
      <div className="main_home_sectionSeven_gen_bodyy__sub">
        <div className="main_home_sectionSix_gen_headingtext">
          Find A Missing Pet
        </div>
        <div className="main_home_sectionSix_gen_body_text">
          Lorem ipsum dolor sit amet enim. Etiam ullamcorper. Suspendisse a
          pellentesque dui, non felis. Maecenas malesuada elit lectus felis,
          malesuada ultricies.
        </div>

        <div className="main_home_sectionSeven_gen_bodyy__substart_hebtn">
          <div className="main_home_sectionSeven_gen_bodyy__substart_hetext">
            LOGIN & REPORT
          </div>
          <div className="main_home_sectionSeven_gen_bodyy__substart_hearrow">
            <img src={arrow_right_} />
          </div>
        </div>

        <div className="main_home_sectionSix_gen_dog_section_seven">
          <img src={dog_section_seven} />
        </div>
      </div>
    </div>
  );
};

export default SectionSeven;
