import React from "react";
import "./sectionThree.scss";
import { filter_micro, section_two_search } from "../../../../assets";
import PetsGrid from "../petsGrid/PetsGrid";

const SectionThree = () => {
  return (
    <div className="main_home_sectionFour_gen_bodyy">
      <div className="main_home_sectionFour_gen_bodyy_topGradient"></div>
      <div className="main_home_sectionFour_gen_bodyy__sub">
        <div className="section_three_micro_pet_head">Pet Shop </div>
        <div className="section_three_micro_pet_microotext">
          Microchipped & vaccinated and other pet items from verified vets &
          kennels.
        </div>

        <div className="micro_sectionThree_top_flexx_left_search__">
          <div className="section_three_flex_left_search_body_microchip">
            <input
              type="text"
              placeholder="Search Breed"
              // value={searchText}
              // onChange={handleSearchChange}
            />
            <div className="section_three_flex_left_search_icon_microchip">
              <img src={section_two_search} />
            </div>
          </div>

          <div className="section_three_flex_left_search___">
            <div className="section_three_flex_left_search___img">
              <img src={filter_micro} />
            </div>
            <div className="section_three_flex_left_search___text">Filter </div>
          </div>
        </div>

        <PetsGrid />
      </div>
    </div>
  );
};

export default SectionThree;
