import React from "react";
import "./index.scss";
import { CopyAll, Email, Payment, Phone } from "@mui/icons-material";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Toast } from "../../../components/toast";

const AnnualDues = () => {
  return (
    <div className="annualDuePageWrap">
      <div className="headerText">
        <h2>Annual Dues </h2>
      </div>

      <div className="annualDuesPage">
        <div className="annualDuesPageCol1">
          <Payment />
        </div>
        <div className="annualDuesPageCol2">
          <h2>Annual Fees </h2>
          <p>Due 16th September, 2023</p>
          <h3>2,500</h3>
          <button>Pay</button>
        </div>
      </div>

      <div className="warningAnn">
        <p>Account is deactivated 30 days after annual fees due date.</p>
      </div>
    </div>
  );
};

export default AnnualDues;
