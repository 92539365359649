import React, { useEffect } from "react";
import "./blog.scss";
import Header from "../../../components/landingPage/header/Header";
import Footer from "../../../components/landingPage/footer/Footer";
import { useLocation } from "react-router-dom";
import SectionOne from "../../../components/landingPage/blog/sectionOne/SectionOne";
import SectionTwo from "../../../components/landingPage/blog/sectionTwo/SectionTwo";

const Blog = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <div>
      <Header />
      <SectionOne />
      <SectionTwo />
      <Footer />
    </div>
  );
};

export default Blog;
