import React from "react";
import "./sectionTwo.scss";
import { section_two_search } from "../../../../assets";

const SectionTwo = () => {
  return (
    <div className="main_home_sectionTwo_gen_bodyy">
      <div className="main_home_sectionTwo_gen_bodyy__sub">
        <div className="main_home_sectionTwo_gen_bodyy_look_upPet">
          Look Up Pet
        </div>
        <div className="main_home_sectionTwo_gen_bodyy_look_uppetext">
          Lorem ipsum dolor sit amet enim. Etiam ullamcorper. Suspendisse a
          pellentesque dui, non felis. Maecenas malesuada elit lectus felis,
          malesuada ultricies.
        </div>

        <div className="section_two_flex_left_search_body">
          <input
            type="text"
            placeholder="Enter Microchip Number"
            // value={searchText}
            // onChange={handleSearchChange}
          />
          <div className="section_two_flex_left_search_icon">
            <img src={section_two_search} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionTwo;
