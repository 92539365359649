import React from "react";
import "./mapComponentAddress.scss";

const MapComponentAddress = () => {
  return (
    <div className="map_component_gen_bodyy_secmicro">
      <div className="map_component_gen_bodyy_secmicro_head">
        WOOF VET CLINIC
      </div>
      <div className="map_component_gen_bodyy_secmicro_subb">
        16, Jide Williams Street, Lekki Phase 1, Lagos
      </div>
      <div className="map_component_gen_bodyy_secmicro_num">
        08065357513, <br /> 07025001972
      </div>
      <div className="map_component_gen_bodyy_secmicro_btn">DIRECTIONS</div>
    </div>
  );
};

export default MapComponentAddress;
