import React, { useState } from "react";
import Table from "./table";
import { axiosCalls } from "../../../_api";
import { useEffect } from "react";
import { hideLoader, showLoader } from "../../../components/loader";
import { Toast } from "../../../components/toast";
import { useHistory } from "react-router-dom";
import VasinationTable from "./vasinationTable";
import "./index.scss";
import useAuth from "../../../hooks/useAuuth";
import { userTypes } from "../../../consts";
import { AccessTime, NotificationAdd } from "@mui/icons-material";
import moment from "moment";

const PetObj = {
  name: "",
  pet_microchip_number: "",
  species: "",
  date_of_birth: "",
  breed: "",
  sex: "",
  color: "",
};
const Notifications = (props) => {
  const user = useAuth();
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [mode, setMode] = useState("cr");
  const [data, setData] = useState([]);
  const [petInfo, setPetInfo] = useState(PetObj);
  const [meta, setMeta] = useState(null);
  const [ids, setIds] = useState([]);
  const [notification, setNotification] = useState([]);

  const getNotifications = async () => {
    let res = null;
    let type = user?.user_type;
    showLoader();

    if (type == userTypes?.petOwner) {
      res = await axiosCalls("user/notifications", "get");
      console.log("RES>", res?.data?.data, "here>>>>");
      setNotification(res?.data?.data ?? []);
      hideLoader();
    }
    if (res && res?.status === 200) {
      hideLoader();

      // setAccount({ ...account, ...res?.data });
    }
  };

  useEffect(() => {
    console.log(props?.match?.params?.id);

    getNotifications();
  }, []);

  return (
    <div>
      <div className="notification-page">
        <div className="headerText">
          <h2>Notifications</h2>
        </div>
        {notification.length == 0 ? <p>No Notification found</p> : null}
        {notification.map((d) => {
          return (
            <div className="noti-card">
              <div className="noti-cardCol1">
                {/* <AccessTime /> */}
                <NotificationAdd />
              </div>
              <div className="noti-cardCol2">
                <h2>{d?.data?.message}</h2>
                <p>
                  {moment(d?.created_at).format("Do MMMM, YYYY h.mm a")}
                  {/* 12th September, 2023 <span>12.02pm</span> */}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Notifications;
