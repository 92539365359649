import React from "react";
import "./sectionOne.scss";
import { dog_section_seven, missing_pet_gradient } from "../../../../assets";
import GradientBtn from "../../gradientBtn/GradientBtn";

const SectionOne = () => {
  return (
    <div className="main_home_micro_sectionOne_gen_bodyy">
      <div className="main_home_micro_sectionOne_gen_bodyy__sub">
        <div className="micro_sectionOne_top_flexxBody">
          <div className="micro_sectionOne_top_flexxImg">
            <img src={missing_pet_gradient} />
          </div>
          <div className="micro_sectionOne_top_flexxtext">
            Lorem ipsum dolor sit amet enim. Etiam ullamcorper. Suspendisse a
            pellentesque dui, non felis. Maecenas malesuada elit lectus felis,
            malesuada ultricies.{" "}
            <span>
              Lorem ipsum dolor sit amet enim. Etiam ullamcorper. Suspendisse a
              pellentesque dui, non felis. Maecenas malesuada elit lectus felis,
              malesuada ultricies.
            </span>
          </div>
          <div className="micro_sectionOne_top_flexxAbsolute">
            MISSING
            <span style={{ color: "#E24F6D", marginLeft: "15px" }}>PET </span>
          </div>
        </div>

        <div className="missing_pets_dog_absolutttt_gradient_body">
          <GradientBtn btnText="LOG IN & REPORT " />
        </div>

        <div className="missing_pets_dog_absolutttt">
          <img src={dog_section_seven} />
        </div>
      </div>
    </div>
  );
};

export default SectionOne;
