import React from "react";
import GradientBtn from "../../gradientBtn/GradientBtn";
import "./sectionTwo.scss";
import { missing_vet___ } from "../../../../assets";

const SectionTwo = () => {
  return (
    <div className="main_home_sectionFour_gen_bodyy">
      <div className="main_home_sectionFour_gen_bodyy_topGradient"></div>
      <div className="main_home_sectionFour_gen_bodyy__sub">
        <div className="missing_pet_head_section_twoooo">
          FOUND A MISSING PET ?
        </div>
        <div className="missing_pet_body_section_twoooo">
          Take it to one of our registered vets and get the pet scanned to
          notify pet owner.{" "}
          <span>
            Lorem ipsum dolor sit amet enim. Etiam ullamcorper. Suspendisse a
            pellentesque dui, non felis. Maecenas malesuada elit lectus felis,
            malesuada ultricies.
          </span>
        </div>
        <div className="missing_pet_body_section_twoooo_btnnn">
          <GradientBtn btnText="FIND VET" />
        </div>
      </div>

      <div className="missing_pet_body_section_______gr">
        <img src={missing_vet___} />
      </div>
    </div>
  );
};

export default SectionTwo;
