import React from "react";
import "./index.scss";
import { CopyAll, Email, Phone } from "@mui/icons-material";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Toast } from "../../../components/toast";

const ContactUs = () => {
  return (
    <div className="contactUsPage">
      <div className="headerText">
        <h2>Contact Us</h2>
      </div>

      <div className="contactUsPageCard">
        <div className="contactUsPageCardCol1">
          <p>
            <span>
              <Phone />
            </span>
            Phone Number
          </p>
          <h2>0701 234 5678</h2>
        </div>
        <div className="contactUsPageCardCol2">
          <CopyToClipboard
            text={"0701 234 5678"}
            onCopy={() => Toast("info", "Copied successfully.")}
          >
            <CopyAll />
          </CopyToClipboard>
          <p>Copy</p>
        </div>
      </div>

      <div className="contactUsPageCard">
        <div className="contactUsPageCardCol1">
          <p>
            <span>
              <Email />
            </span>
            Email Address
          </p>
          <h2>info@nationalpetdatabase.com</h2>
        </div>
        <div className="contactUsPageCardCol2">
          <CopyToClipboard
            text={"info@nationalpetdatabase.com"}
            onCopy={() => Toast("info", "Copied successfully.")}
          >
            <CopyAll />
          </CopyToClipboard>
          <p>Copy</p>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
