import React, { useState, useEffect } from "react";
import { Modal as ModalC } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import MaterialTable, { Column } from "material-table";
import { Paper } from "@material-ui/core";
import { NotificationManager } from "react-notifications";
// import "./index.css";
import { axiosCalls } from "../../../_api";
import icons from "../../../components/table/icons";
import { turnDate } from "../../../components/helper";
import Input from "../../../components/form/input";
import Sizebox from "../../../components/sizebox";
import FileUploader from "../../../components/form/fileUploader";
import SelectBox from "../../../components/form/select";
import { Button } from "@mui/material";
import { useHistory } from "react-router-dom";
import GridTable from "../../../components/tableCom/table";

export default function Table({
  onOpenModal,
  onOpenModalTracks,
  data,
  deleteRole,
  reloadIsDelete,
  showModal,
  handleClose,
  mode,
  handleEdit,
  petInfo,
  handleChange,
  handleSubmit,
  handleActivationAdd,
  ids,
  openInvoice,
  reloadTable,
  setReloadTable,
}) {
  const history = useHistory();

  const [edit, setEdit] = useState({});
  const [roleId, setRoleId] = useState();
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteParam, setDeleteParam] = useState();

  const onOpenModalDelete = () => setOpenDelete(true);
  const onCloseModalDelete = () => setOpenDelete(false);

  const handleDelete = (data) => {
    onOpenModalDelete();
    setDeleteParam(data);
  };

  return (
    <div className="table-wrap border styleTable">
      <GridTable
        title="All Pets"
        columns={[
          {
            title: "Pet Name",
            field: "select",
            render: (rowData) => {
              return (
                <div style={{ display: "flex", alignItems: "center" }}>
                  {rowData?.status === "Inactive" ? (
                    <input
                      type="checkbox"
                      style={{ cursor: "pointer" }}
                      onChange={(e) => handleActivationAdd(e, rowData)}
                      checked={ids.includes(rowData?.id)}
                    />
                  ) : null}
                  <Sizebox width="10px" />
                  <span> {rowData?.name}</span>
                </div>
              );
            },
          },

          { title: "Breed", field: "breed" },

          { title: "Color", field: "colour" },

          { title: "Sex", field: "sex" },

          { title: "Pet Microchip Number", field: "microchip_number" },
          { title: "Status", field: "status" },
          {
            title: "Actions",
            field: "action",
            render: (rowData) => (
              <div>
                <span
                  style={{
                    cursor: "pointer",
                    marginRight: "20px",
                    color: "green",
                  }}
                  className="pending-action-table-btn"
                  onClick={() => handleEdit(rowData)}
                >
                  Edit Pet
                </span>

                <span
                  style={{
                    cursor: "pointer",
                    marginRight: "20px",
                    color: "green",
                  }}
                  className="pending-action-table-btn"
                  onClick={() => history.push(`/vaccination/${rowData?.id}`)}
                >
                  Vaccination Details
                </span>
              </div>
            ),
          },
        ]}
        path="pet-owner/pets"
        reloadTable={reloadTable}
        setReload={setReloadTable}
      />

      {ids.length > 0 ? (
        <div>
          <br />
          <button onClick={openInvoice}>Get Invoice</button>
        </div>
      ) : null}

      <ModalC open={showModal} onClose={handleClose} center>
        <div className="successModal">
          <h2>{mode == "cr" ? "Add" : "Edit"} Pet</h2>

          <Input
            onChange={handleChange}
            name="name"
            value={petInfo?.name}
            placeholder={"Pet Name"}
            // type="number"
          />

          <Sizebox height="20px" />
          <Input
            onChange={handleChange}
            name="date_of_birth"
            value={new Date(petInfo?.date_of_birth) ?? new Date()}
            placeholder={"Pet’s DOB"}
            type="date"
          />

          <Sizebox height="20px" />

          <Input
            onChange={handleChange}
            name="color"
            value={petInfo?.color}
            placeholder={"Pet Color"}
            // type="number"
          />
          <Sizebox height="20px" />

          <SelectBox
            // label={"Select sex"}
            value={petInfo?.sex}
            name="sex"
            onChange={handleChange}
            options={[
              { label: "Male", value: "MALE", key: "male" },
              { label: "Female", value: "FEMALE", key: "Female" },
              { label: "Others", value: "others", key: "Others" },
            ]}
          />

          <Sizebox height="20px" />
          <Input
            onChange={handleChange}
            name="breed"
            value={petInfo?.breed}
            placeholder={"GSD"}
            type="text"
          />

          <Sizebox height="20px" />
          <Input
            onChange={handleChange}
            name="species"
            value={petInfo?.species}
            placeholder={"Species"}
            type="text"
          />

          <Sizebox height="20px" />
          <Input
            onChange={handleChange}
            name="pet_microchip_number"
            value={petInfo?.pet_microchip_number}
            placeholder={"Pet’s Microchip Number"}
            type="number"
          />
          <Sizebox height="15px" />

          <FileUploader
            onChange={handleChange}
            name="petMicrochipFile"
            value={petInfo?.petMicrochipFile}
            placeholder={"Pet’s Microchip Number"}
            type="file"
            label={"Upload Microchip Certificate"}
          />

          {/* <div className="mdInputWrap">
            <label htmlFor="">Description</label>
            <input
              type="text"
              name="description"
              value={edit.description}
              placeholder="Description"
              onChange={onChangeEdit}
            />
          </div> */}

          <div className="sendRequestBtn">
            <button onClick={handleSubmit}>Save</button>
          </div>
        </div>
      </ModalC>

      <ModalC open={openDelete} onClose={onCloseModalDelete} center>
        <div className="deleteHeader">Delete Role?</div>
        <div className="deleteSubText">
          Deleting this role is irreversible do you want to continue?
        </div>

        <div
          onClick={() => {
            deleteRole(deleteParam);
            onCloseModalDelete();
            reloadIsDelete();
          }}
          className="deleteYesBtn"
        >
          Yes
        </div>
        <div className="deleteNoBtn" onClick={onCloseModalDelete}>
          No
        </div>
      </ModalC>
    </div>
  );
}
