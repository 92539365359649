import React, { useState } from "react";
import { useEffect } from "react";
import { Modal as ModalC } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { Delete, Edit, Pets, RemoveRedEye } from "@mui/icons-material";
import ProfileForm from "./form";
import useAuth from "../../hooks/useAuuth";
import { hideLoader, showLoader } from "../../components/loader";
import { axiosCalls } from "../../_api";
import { userTypes } from "../../consts";
import { Toast } from "../../components/toast";
import validatePassword from "../../components/validatePassword";
import ProfileImage from "../../components/profileImage";
import Sizebox from "../../components/sizebox";
import Input from "../../components/form/input";
import "./index.scss";
import { useHistory } from "react-router-dom";

const PetForSale = () => {
  const user = useAuth();
  const history = useHistory();
  const [account, setAccount] = useState({ first_name: "", last_name: "" });

  return (
    <div className="petForSale">
      <div className="petForSaleHeader">
        <h2>Pet’s for Sale</h2>
        <div
          className="petBtn"
          onClick={() => {
            history.push(`pet_for_sale/create`);
          }}
        >
          <p>Add Pet</p>
        </div>
      </div>

      <div className="petForSaleWrap">
        {[..."..................................."].map((item) => {
          return (
            <div className="petForSaleCon">
              <div className="petActions">
                <Edit />
                <Delete />
              </div>
              <div className="petImageWrap">
                <div className="petImage">
                  <Pets />
                </div>
              </div>

              <div className="petName">
                <h2>French Bull Dogs</h2>
                <button>VIEW DETAILS</button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PetForSale;
