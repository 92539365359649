import React, { useEffect } from "react";
import "./mainHome.scss";
import Header from "../../../components/landingPage/header/Header";
import SectionOne from "../../../components/landingPage/home/sectionOne/SectionOne";
import SectionTwo from "../../../components/landingPage/home/sectionTwo/SectionTwo";
import Footer from "../../../components/landingPage/footer/Footer";
import SectionThree from "../../../components/landingPage/home/sectionThree/SectionThree";
import SectionFour from "../../../components/landingPage/home/sectionFour/SectionFour";
import SectionFIve from "../../../components/landingPage/home/sectionFive/SectionFIve";
import SectionSix from "../../../components/landingPage/home/sectionSix/SectionSix";
import SectionSeven from "../../../components/landingPage/home/sectionSeven/SectionSeven";
// import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useLocation } from "react-router-dom";

const MainHome = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <div className="main_home_body">
      <Header />
      <SectionOne />
      <SectionTwo />
      <SectionThree />
      <SectionFour />
      <SectionFIve />
      <SectionSix />
      <SectionSeven />
      <Footer />
    </div>
  );
};

export default MainHome;
