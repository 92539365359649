import { Paper } from "@mui/material";
import MaterialTable from "material-table";
import icons from "../table/icons";
import { hideLoader, showLoader } from "../loader";
import { useEffect, useState } from "react";
import { axiosCalls } from "../../_api";

const GridTable = ({
  columns = [],
  title = "",
  path = "",
  reloadTable = false,
  setReload,
}) => {
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState({
    current_page: 1,
    per_page: 15,
    total: 0,
    last_page: 1,
  });
  const [searchQuery, setSearchQuery] = useState(""); // Search state
  const [tableQuery, setTableQuery] = useState({}); // Table query state

  const call = async (page = 1, searchTerm = "") => {
    if (path === "") return;

    showLoader();
    const res = await axiosCalls(
      `${path}?page=${page}&search=${searchTerm}`,
      "get"
    );
    hideLoader();

    if (res && res?.status === 200) {
      setData(res?.data?.data);
      setMeta({
        current_page: res?.data?.meta?.current_page,
        per_page: res?.data?.meta?.per_page,
        total: res?.data?.meta?.total,
        last_page: res?.data?.meta?.last_page,
      });
    }
  };

  const fetchData = async (query) => {
    const page = query.page + 1;
    const search = query.search || searchQuery;
    await call(page, search);
    setTableQuery(query);
  };

  useEffect(() => {
    fetchData({ page: meta.current_page - 1, search: searchQuery });
  }, [meta.current_page, searchQuery]);

  // Reset table to default states when reloadTable changes to true
  useEffect(() => {
    if (reloadTable) {
      // Reset to default state
      setSearchQuery(""); // Clear search query
      setMeta({
        current_page: 1,
        per_page: 15,
        total: 0,
        last_page: 1,
      });
      fetchData({ page: 0, search: "" }); // Fetch initial data

      // After fetching, set reload back to false
      setReload(false);
    }
  }, [reloadTable, setReload]); // Add setReload to dependencies

  return (
    <div>
      {/* Material Table */}
      <MaterialTable
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        icons={icons}
        options={{
          actionsColumnIndex: -1,
          pageSize: meta.per_page,
          paginationType: "stepped",
          emptyRowsWhenPaging: false,
          search: true,
          debounceInterval: 500,
          loading: false,
        }}
        columns={columns}
        data={data}
        title={title}
        totalCount={meta.total}
        page={meta.current_page - 1}
        onChangePage={(page) => fetchData({ page, search: searchQuery })}
        onSearchChange={(newSearch) => {
          setSearchQuery(newSearch);
          fetchData({ page: 0, search: newSearch });
        }}
      />
    </div>
  );
};

export default GridTable;
