import Input from "../../components/form/input";
import SelectBox from "../../components/form/select";
import { userTypes } from "../../consts";

const ProfileForm = ({ account, handleChange, type }) => {
  const loginUser = userTypes;
  return (
    // loginUser.petOwner === type ||
    //     (loginUser.vetClinic === type
    <>
      {loginUser.petOwner === type || loginUser.vetClinic === type ? (
        <div className="generalFormContainer">
          <div className="generalForm">
            <div className="generalFormChild">
              <Input
                label={"First Name"}
                value={account?.first_name}
                name="first_name"
                onChange={handleChange}
              />
            </div>

            <div className="generalFormChild">
              <Input
                label={"Email address"}
                value={account?.email}
                // name="email"
                // onChange={handleChange}
                disabled={true}
              />
            </div>

            <div className="generalFormChild">
              <Input
                label={"Last Name"}
                value={account?.last_name}
                name="last_name"
                onChange={handleChange}
              />
            </div>

            <div className="generalFormChild">
              <Input
                label={"Address"}
                value={account?.address}
                name="address"
                onChange={handleChange}
              />
            </div>

            <div className="generalFormChild">
              <Input
                label={"Middle Name"}
                value={account?.middle_name}
                name="middle_name"
                onChange={handleChange}
              />
            </div>

            <div className="generalFormChild">
              <SelectBox
                label={"Select gender"}
                value={account?.gender}
                name="gender"
                onChange={handleChange}
                options={[
                  { label: "Male", value: "male", key: "male" },
                  { label: "Female", value: "female", key: "Female" },
                  { label: "Others", value: "others", key: "Others" },
                ]}
              />
            </div>

            <div className="generalFormChild">
              <Input
                label={"Phone number"}
                value={account?.phone_number}
                name="phone_number"
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ProfileForm;
