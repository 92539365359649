import React, { useState } from "react";
import "./petsGrid.scss";
import {
  chevron_left,
  chevron_right,
  section_six_img,
  section_six_img_two,
  section_six_imgOne,
  section_six_imgTwo,
} from "../../../../assets";

const petData = [
  { name: "French Bull Dogs", img: section_six_img },
  { name: "Siamese Cats", img: section_six_imgTwo },
  { name: "Holland Lop Rabbits", img: section_six_imgOne },
  { name: "French Bull Dogs", img: section_six_img_two },
  { name: "Holland Lop Rabbits", img: section_six_imgOne },
  { name: "Siamese Cats", img: section_six_imgTwo },
  { name: "French Bull Dogs", img: section_six_img_two },
  { name: "French Bull Dogs", img: section_six_img_two },
  { name: "Holland Lop Rabbits", img: section_six_imgOne },
];

const PetsGrid = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6; // Items per page

  // Calculate the items for the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentPets = petData.slice(indexOfFirstItem, indexOfLastItem);

  // Handle pagination
  const totalPages = Math.ceil(petData.length / itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="pets-container">
      <div className="grid-container">
        {currentPets.map((pet, index) => (
          <div key={index} className="pet-card">
            <img src={pet.img} alt={pet.name} className="pet-image" />
            <div className="pet-card-h3">{pet.name}</div>
            <button className="details-button">VIEW DETAILS</button>
          </div>
        ))}
      </div>

      {/* Pagination Controls */}
      <div className="pagination">
        <button
          className="pagination_chev_left"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          <img src={chevron_left} />
        </button>
        {[...Array(totalPages).keys()].map((page) => (
          <button
            key={page + 1}
            className={
              currentPage === page + 1 ? "active" : "none_active_pagination_btn"
            }
            onClick={() => handlePageClick(page + 1)}
          >
            {page + 1}
          </button>
        ))}
        <button
          className="pagination_chev_right"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          <img src={chevron_right} />
        </button>
      </div>
    </div>
  );
};

export default PetsGrid;
