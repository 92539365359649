import React from "react";
import "./sectionOne.scss";
import {
  arrow_right_,
  main_homepet_hold,
  section_one_dog,
  section_one_dog_gradient,
  section_one_rope,
} from "../../../../assets";

const SectionOne = () => {
  return (
    <div className="main_home_sectionOne_gen_bodyy">
      <div className="main_home_sectionOne_gen_bodyy__sub">
        <div className="main_home_sectionOne_gen_sub_textt">
          STAY CONNECTED WITH YOUR <br />
          FURRY FRIENDS!
        </div>
        <div className="main_home_sectionOne_gen_sub_hold">
          <img src={main_homepet_hold} />
        </div>
      </div>
      <div className="main_home_sectionOne_gen_sub_rope">
        <img src={section_one_rope} />
      </div>

      <div className="main_home_sectionOne_gen_reg_btn">
        <div className="main_home_sectionOne_gen_reg_btn_text">
          REGISTER YOUR PET
        </div>
        <div className="main_home_sectionOne_gen_reg_btn_arrowright">
          <img src={arrow_right_} />
        </div>
      </div>
      <div className="main_home_sectionOne_gen_sub_dog_gradient_b">
        <div className="main_home_sectionOne_gen_gradient">
          <img src={section_one_dog_gradient} />
        </div>
        <div className="main_home_sectionOne_gen_gradient_dog">
          <img src={section_one_dog} />
        </div>
      </div>
    </div>
  );
};

export default SectionOne;
