import React, { useState } from "react";
import Table from "./table";
import { axiosCalls } from "../../../_api";
import { useEffect } from "react";
import { hideLoader, showLoader } from "../../../components/loader";
import { Toast } from "../../../components/toast";
import { Modal as ModalC } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
const PetObj = {
  name: "",
  pet_microchip_number: "",
  species: "",
  date_of_birth: "",
  breed: "",
  sex: "",
  color: "",
};
const PetProfileTable = () => {
  const [showModal, setShowModal] = useState(false);
  const [showModalInvoice, setShowModalInvoice] = useState(false);
  const [mode, setMode] = useState("cr");
  const [data, setData] = useState([]);
  const [petInfo, setPetInfo] = useState(PetObj);
  const [meta, setMeta] = useState(null);
  const [ids, setIds] = useState([]);
  const [invoiceDetails, setInvoiceDetails] = useState(null);
  const [pets, setPets] = useState([]);
  const [reloadTable, setReloadTable] = useState(false);

  const handleClose = () => {
    setShowModal(false);
    setPetInfo();
  };

  const getPets = async () => {
    showLoader();
    const res = await axiosCalls("pet-owner/pets", "get");
    hideLoader();
    if (res && res?.status == 200) {
      console.log(res);
      setData(res?.data?.data);
      setMeta(res?.data);
    }
  };

  const handleEdit = (pet) => {
    let info = {
      ...pet,
      name: pet.name,
      pet_microchip_number: pet?.microchip_number,
      species: pet?.species,
      date_of_birth: pet?.date_of_birth,
      breed: pet?.breed,
      sex: pet?.sex,
      color: pet?.colour,
    };
    setPetInfo(info);
    setMode("ed");
    setShowModal(true);
    console.log(info);
  };

  const handleChange = (event) => {
    setPetInfo({ ...petInfo, [event?.target?.name]: event?.target?.value });
  };

  const handleSubmit = async () => {
    let requiredFields = {
      name: "Pet Name",
      pet_microchip_number: "Microchip Number",
      species: "species",
      date_of_birth: "Date of birth",
      breed: "breed",
      sex: "sex",
      color: "Pet Color",
    };

    for (const field in requiredFields) {
      if (!petInfo[field]) {
        const fieldName = requiredFields[field];
        Toast("error", `${fieldName} is required`);
        return false;
      }
    }
    const basicInfo = {
      name: petInfo?.name,
      microchip_number: petInfo?.pet_microchip_number,
      species: petInfo?.species,
      date_of_birth: petInfo?.date_of_birth,
      breed: petInfo?.breed,
      sex: petInfo?.sex,
      colour: petInfo?.color,
    };
    let res = null;
    showLoader();
    if (mode == "cr") {
      res = await axiosCalls("pet-owner/pets", "post", basicInfo);
    } else {
      res = await axiosCalls(
        `pet-owner/pets/${petInfo?.id}`,
        "PATCH",
        basicInfo
      );
    }
    hideLoader();
    hideLoader();
    if ((res && res?.status == 201) || res?.status == 200) {
      getPets();
      setShowModal(false);
      setPetInfo(PetObj);
      setReloadTable(true);
    } else {
      console.log(res?.er?.data?.message);
      Toast("error", res?.er?.data?.message);
    }
  };

  const handleActivationAdd = (e, row) => {
    console.log(row);
    const { id } = row;

    if (ids.includes(id)) {
      const newIds = ids.filter((newId) => newId !== id);
      const newPets = pets.filter((existingPet) => existingPet?.id !== id);

      console.log(newIds);
      setIds(newIds);
      setPets(newPets);
    } else {
      setIds([...ids, id]);
      setPets([...pets, row]);
      console.log(ids);
    }
  };

  const openInvoice = async () => {
    await getInvoiceDetails();
    setShowModalInvoice(true);
  };

  const getInvoiceDetails = async () => {
    showLoader();
    const res = await axiosCalls(
      "pet-owner/pet-payment/create-invoice",
      "POST",
      {
        total: ids.length,
        pets: ids,
      }
    );
    if (res) {
      if (res?.er) {
        hideLoader();
        Toast("error", `${res?.er?.data?.message}`);
        return;
      }
      console.log(res?.data);
      // setInvoiceDetails(res?.data?.data ?? []);

      hideLoader();
    }
  };

  useEffect(() => {
    getPets();
  }, []);

  useEffect(() => {
    console.log(pets);
  }, [pets]);

  return (
    <div>
      <div className="dashboardMainTable">
        <div className="viewAll">
          <button
            onClick={() => {
              setMode("cr");
              setShowModal(true);
            }}
          >
            Add Pet
          </button>
        </div>
        <Table
          onOpenModal={() => {}}
          onOpenModalTracks={() => {}}
          // onOpenModalEdit={onOpenModalEdit}
          deleteRole={() => {}}
          data={data ?? []}
          reloadIsDelete={false}
          isDeleted={false}
          showModal={showModal}
          handleClose={handleClose}
          mode={mode}
          handleEdit={handleEdit}
          petInfo={petInfo}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          handleActivationAdd={handleActivationAdd}
          ids={ids}
          openInvoice={openInvoice}
          reloadTable={reloadTable}
          setReloadTable={setReloadTable}
        />
      </div>

      <ModalC
        open={showModalInvoice}
        onClose={() => {
          setShowModalInvoice(false);
        }}
        center
      >
        <div className="successModal">
          <h2>Invoice</h2>

          <div className="invoiceList737">
            <div className="invoiceListCol1">
              <h2>Total Pet</h2>
            </div>

            <div className="invoiceListCol2">
              <p>2092</p>
            </div>
          </div>

          <div className="invoiceList737">
            <div className="invoiceListCol1">
              <h2>Total Amount</h2>
            </div>

            <div className="invoiceListCol2">
              <p>2092</p>
            </div>
          </div>

          <div className="sendRequestBtn">
            <button onClick={handleSubmit}>Make Payment</button>
          </div>
        </div>
      </ModalC>
    </div>
  );
};

export default PetProfileTable;
