import React from "react";
import "./sectionTwo.scss";
import BlogPostCard from "../blogPostCard/BlogPostCard";

const SectionTwo = () => {
  return (
    <div className="main_home_micro_sectionOne_gen_bodyy">
      <div className="main_home_micro_sectionOne_gen_bodyy__sub">
        <div className="section_two_blog_post_body_gennn_co">
          <BlogPostCard />
          <BlogPostCard />
          <BlogPostCard />
          <BlogPostCard />
          <BlogPostCard />
          <BlogPostCard />
          <BlogPostCard />
          <BlogPostCard />
        </div>
      </div>
    </div>
  );
};

export default SectionTwo;
