import React, { useState } from "react";
import Input from "../../../../components/form/input";
import "./index.scss";
import Sizebox from "../../../../components/sizebox";
import FileUploader from "../../../../components/form/fileUploader";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const VetAccount = ({ handleChange, account }) => {
  const [showPass, setShowPass] = useState(false);
  const [showPassCon, setShowPassCon] = useState(false);
  return (
    <div className="petOwner">
      <div className="petOwnerDes">
        <div className="dimacation"></div>
      </div>
      <Input
        onChange={handleChange}
        name="firstName"
        value={account?.firstName}
        placeholder={"First name"}
      />
      <Sizebox height="20px" />
      <Input
        onChange={handleChange}
        name="lastName"
        value={account?.lastName}
        placeholder={"Last name"}
      />
      <Sizebox height="20px" />
      <Input
        onChange={handleChange}
        name="phoneNumber"
        value={account?.phoneNumber}
        placeholder={"Phone number"}
        type="number"
      />
      <Sizebox height="20px" />
      <Input
        onChange={handleChange}
        name="clinicAddress"
        value={account?.clinicAddress}
        placeholder={"Clinic address"}
      />

      <Sizebox height="20px" />
      <Input
        onChange={handleChange}
        name="email"
        value={account?.email}
        placeholder={"Email"}
        type="email"
      />

      <Sizebox height="20px" />
      <Input
        onChange={handleChange}
        name="CACNumber"
        value={account?.CACNumber}
        placeholder={"CAC number"}
      />

      <Sizebox height="20px" />
      <Input
        onChange={handleChange}
        name="veterinaryCouncilNumber"
        value={account?.veterinaryCouncilNumber}
        placeholder={"Veterinary council number"}
        type="number"
      />

      <Sizebox height="20px" />
      <Input
        onChange={handleChange}
        name="password"
        value={account?.password}
        placeholder="Password"
        type={showPass ? "text" : "password"}
        IconRightClick={() => {
          setShowPass(!showPass);
        }}
        IconRight={showPass ? Visibility : VisibilityOff}
      />

      <Sizebox height="20px" />
      <Input
        onChange={handleChange}
        name="ConfirmPassword"
        value={account?.ConfirmPassword}
        placeholder="Confirm Password"
        type={showPassCon ? "text" : "password"}
        IconRightClick={() => {
          setShowPassCon(!showPassCon);
        }}
        IconRight={showPassCon ? Visibility : VisibilityOff}
      />
    </div>
  );
};

export default VetAccount;
