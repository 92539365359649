import React, { useState } from "react";
import Input from "../../../../components/form/input";
import "./index.scss";
import Sizebox from "../../../../components/sizebox";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const LoginScreen = ({ handleChange, account, history }) => {
  const [showPass, setShowPass] = useState(false);

  return (
    <div className="petOwner">
      <Sizebox height="20px" />
      <Input
        onChange={handleChange}
        name="email"
        value={account?.email}
        placeholder={"Email"}
        type="email"
      />

      <Sizebox height="20px" />
      <Input
        onChange={handleChange}
        name="password"
        value={account?.password}
        placeholder="Password"
        type={showPass ? "text" : "password"}
        IconRightClick={() => {
          setShowPass(!showPass);
        }}
        IconRight={showPass ? Visibility : VisibilityOff}
      />
      <div className="forgotPass">
        <p>
          Forgot Password?{" "}
          <span
            onClick={() => {
              history.push("/auth_reset_password");
            }}
          >
            Reset
          </span>
        </p>
      </div>
    </div>
  );
};

export default LoginScreen;
