import React, { useState } from "react";
import AuthWrap from "../authWrap";
import "./index.scss";
import AuthPet1 from "../../../assets/images/authPet1.png";
import { useEffect } from "react";
import LoginScreen from "./verifyScreen";
import { useHistory } from "react-router-dom";
import { axiosCalls } from "../../../_api";
import { Toast } from "../../../components/toast/index";
import validateEmail from "../../../components/validateEmail";
import validatePassword from "../../../components/validatePassword";
import { useDispatch } from "react-redux";
import { setUser } from "../../../reducers/auth";
import { hideLoader, showLoader } from "../../../components/loader";
import VerifyEmailScreen from "./verifyScreen";

const VerifyEmail = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [account, setAccount] = useState({
    email: props?.match?.params?.email,
    code: "",
  });

  const handleChange = (event) => {
    console.log(event);
    setAccount({ ...account, [event?.target?.name]: event?.target?.value });
  };

  const sendOtp = async () => {
    const emailV = validateEmail(account.email);

    if (emailV !== true) {
      return Toast("error", "Invalid Email");
    }

    const data = {
      email: account?.email,
    };
    showLoader();
    const res = await axiosCalls(
      "auth/email/verification-notification",
      "POST",
      data
    );
    if (res) {
      if (res?.er) {
        hideLoader();
        Toast("error", `${res?.er?.data?.message}`);
        return;
      }
      hideLoader();
      Toast("success", "An OTP has been sent to your email");
    }
  };

  const verifyOtp = async () => {
    const emailV = validateEmail(account.email);

    if (account?.code == "") {
      return Toast("error", "Input OTP");
    }

    if (emailV !== true) {
      return Toast("error", "Invalid Email");
    }

    const data = {
      email: account?.email,
      code: account?.code,
    };
    showLoader();
    const res = await axiosCalls("auth/email/verify", "POST", data);
    if (res) {
      if (res?.er) {
        hideLoader();
        Toast("error", `${res?.er?.data?.message}`);
        return;
      }
      hideLoader();
      Toast("success", "Account successfully verified");
      history.push("/login");
    }
  };

  useEffect(() => {
    console.log(props?.match?.params?.from);
    if (props?.match?.params?.from === "login") {
      sendOtp();
    }
  }, [props?.match?.params?.email]);

  return (
    <AuthWrap
      petImageBottom={AuthPet1}
      onChange={handleChange}
      name="accountType"
      value={account.accountType}
      handleSubmit={verifyOtp}
      header="Verify your account"
      subHeader={`Please input code sent to ${account?.email}`}
      subBtnText={`Verify Email`}
      type="login"
      showDrop={false}
    >
      <VerifyEmailScreen
        handleChange={handleChange}
        account={account}
        history={history}
        sendOtp={sendOtp}
      />
    </AuthWrap>
  );
};

export default VerifyEmail;
