import React from "react";
import "./footer.scss";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer_gen_bodyy">
      <div className="footer_gen_bodyy__sub">
        <ul>
          <li>
            <NavLink exact to="/">
              About Us
            </NavLink>
          </li>
          <li>
            <NavLink exact to="/">
              Blog
            </NavLink>
          </li>
          <li>
            <NavLink exact to="/">
              Vet Clinics
            </NavLink>
          </li>
          <li>
            <NavLink exact to="/">
              Kennels
            </NavLink>
          </li>
          <li>
            <NavLink exact to="/">
              Support
            </NavLink>
          </li>
        </ul>
        <ul>
          <li>
            <NavLink exact to="/">
              About Us
            </NavLink>
          </li>
          <li>
            <NavLink exact to="/">
              Blog
            </NavLink>
          </li>
          <li>
            <NavLink exact to="/">
              Vet Clinics
            </NavLink>
          </li>
          <li>
            <NavLink exact to="/">
              Kennels
            </NavLink>
          </li>
          <li>
            <NavLink exact to="/">
              Support
            </NavLink>
          </li>
        </ul>
        <ul>
          <li>
            <NavLink exact to="/">
              About Us
            </NavLink>
          </li>
          <li>
            <NavLink exact to="/">
              Blog
            </NavLink>
          </li>
          <li>
            <NavLink exact to="/">
              Vet Clinics
            </NavLink>
          </li>
          <li>
            <NavLink exact to="/">
              Kennels
            </NavLink>
          </li>
          <li>
            <NavLink exact to="/">
              Support
            </NavLink>
          </li>
        </ul>
        <ul>
          <li>
            <NavLink exact to="/">
              About Us
            </NavLink>
          </li>
          <li>
            <NavLink exact to="/">
              Blog
            </NavLink>
          </li>
          <li>
            <NavLink exact to="/">
              Vet Clinics
            </NavLink>
          </li>
          <li>
            <NavLink exact to="/">
              Kennels
            </NavLink>
          </li>
          <li>
            <NavLink exact to="/">
              Support
            </NavLink>
          </li>
        </ul>
        <ul>
          <li>
            <NavLink exact to="/">
              About Us
            </NavLink>
          </li>
          <li>
            <NavLink exact to="/">
              Blog
            </NavLink>
          </li>
          <li>
            <NavLink exact to="/">
              Vet Clinics
            </NavLink>
          </li>
          <li>
            <NavLink exact to="/">
              Kennels
            </NavLink>
          </li>
          <li>
            <NavLink exact to="/">
              Support
            </NavLink>
          </li>
        </ul>
      </div>
      <div className="footer_gen_bodyy__sub_copyright">
        {" "}
        &copy; Pet National Database 2023
      </div>
    </div>
  );
};

export default Footer;
