import React from "react";
import { ProtectedLayout } from "./components/layout/index";
import HomePageRoute from "./pages/home/index.jsx";
import ShipmentRoute from "./pages/projectcategories/index";
import Category from "./pages/categories/categories";
import AdminRoute from "./pages/admins/index";
import Login from "./pages/auth/login";
import ViewShipment from "./pages/projectcategories/singleShip";
import DownloadAudiopAGE from "./pages/public";
import RoleRoute from "./pages/roles/index";
import PetProfileTable from "./pages/petOwnerPages/petProfile/index.jsx";
import Vaccination from "./pages/petOwnerPages/petProfile/versination.jsx";
import Notifications from "./pages/petOwnerPages/petProfile/notifications.jsx";
import ContactUs from "./pages/petOwnerPages/petProfile/contactUs.jsx";
import AnnualDues from "./pages/petOwnerPages/petProfile/annualDues.jsx";
import ReportMissingPet from "./pages/petOwnerPages/petProfile/reportMissingPet.jsx";
import MainHome from "./pages/home/mainHome/MainHome.jsx";
import Microchipping from "./pages/home/microchipping/Microchipping.jsx";
import PartnerWithUs from "./pages/home/partnerWithUs/PartnerWithUs.jsx";
import MissingPets from "./pages/home/missingPets/MissingPets.jsx";
import Support from "./pages/home/support/Support.jsx";
import Blog from "./pages/home/blog/Blog.jsx";
import VerifyEmail from "./pages/auth/verifyEmail/verify.jsx";
import CompanyProfile from "./pages/companyProfile/index.jsx";
import PetForSaleForm from "./pages/petForSale/formIndex.jsx";
import PetOwnerProfile from "./pages/ownerProfile/index.jsx";
import PetForSale from "./pages/petForSale/index.jsx";

export const HomePage = (props) => {
  return (
    <ProtectedLayout>
      <HomePageRoute {...props} />
    </ProtectedLayout>
  );
};

export const PetOwnerProfilePage = (props) => {
  return (
    <ProtectedLayout>
      <PetOwnerProfile {...props} />
    </ProtectedLayout>
  );
};

export const CompanyProfilePage = (props) => {
  return (
    <ProtectedLayout>
      <CompanyProfile {...props} />
    </ProtectedLayout>
  );
};

export const PetForSalePage = (props) => {
  return (
    <ProtectedLayout>
      <PetForSale {...props} />
    </ProtectedLayout>
  );
};

export const PetForSaleFormPage = (props) => {
  return (
    <ProtectedLayout>
      <PetForSaleForm {...props} />
    </ProtectedLayout>
  );
};

export const PetProfileTablePage = (props) => {
  return (
    <ProtectedLayout>
      <PetProfileTable {...props} />
    </ProtectedLayout>
  );
};

export const VaccinationPage = (props) => {
  return (
    <ProtectedLayout>
      <Vaccination {...props} />
    </ProtectedLayout>
  );
};

export const NotificationPage = (props) => {
  return (
    <ProtectedLayout>
      <Notifications {...props} />
    </ProtectedLayout>
  );
};

export const ContactUsPage = (props) => {
  return (
    <ProtectedLayout>
      <ContactUs {...props} />
    </ProtectedLayout>
  );
};

export const AnnualDuesPage = (props) => {
  return (
    <ProtectedLayout>
      <AnnualDues {...props} />
    </ProtectedLayout>
  );
};

export const ReportMissingPetPage = (props) => {
  return (
    <ProtectedLayout>
      <ReportMissingPet {...props} />
    </ProtectedLayout>
  );
};

export const ShipmentPage = (props) => {
  return (
    <ProtectedLayout>
      <ShipmentRoute {...props} />
    </ProtectedLayout>
  );
};

export const PrayerProjectCategories = (props) => {
  return (
    <ProtectedLayout>
      <Category {...props} />
    </ProtectedLayout>
  );
};

export const AdminPage = (props) => {
  return (
    <ProtectedLayout>
      <AdminRoute {...props} />
    </ProtectedLayout>
  );
};

export const RolesPage = (props) => {
  return (
    <ProtectedLayout>
      <RoleRoute {...props} />
    </ProtectedLayout>
  );
};

export const ViewShipmentPage = (props) => {
  return (
    <ProtectedLayout>
      <ViewShipment {...props} />
    </ProtectedLayout>
  );
};

export const LoginPage = () => {
  return <Login />;
};

export const VerifyEmailPage = (props) => {
  return <VerifyEmail {...props} />;
};

export const MainHomePage = () => {
  return <MainHome />;
};

export const MicrochippingPage = () => {
  return <Microchipping />;
};
export const PartnerWithUsPage = () => {
  return <PartnerWithUs />;
};
export const MissingPetsPage = () => {
  return <MissingPets />;
};
export const SupportPage = () => {
  return <Support />;
};
export const BlogPage = () => {
  return <Blog />;
};
export const DownloadAudio = (props) => {
  return <DownloadAudiopAGE {...props} />;
};
