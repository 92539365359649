import React from "react";
import "./contactUs.scss";
import {
  call_support_icon,
  location_map_icon,
  message_support_icon,
} from "../../../../assets";

const ContactUs = () => {
  return (
    <div className="contact_ussupport-container">
      <div className="contact_ussupport_header_su">
        Cant find what you’re looking for? Get in touch
      </div>
      <div className="contact_ussupport_header_su_bodyy">
        <div className="contact_ussupport_header_su_bodyy_icon">
          <img src={call_support_icon} />
        </div>{" "}
        <div className="contact_ussupport_header_su_bodyy_text">
          07012345678
          <span>08034567892</span>
        </div>
      </div>
      <div className="contact_ussupport_header_su_bodyy">
        <div className="contact_ussupport_header_su_bodyy_icon">
          <img src={message_support_icon} />
        </div>{" "}
        <div className="contact_ussupport_header_su_bodyy_text">
          info@petdatabase.com
        </div>
      </div>
      <div className="contact_ussupport_header_su_bodyy">
        <div className="contact_ussupport_header_su_bodyy_icon">
          <img src={location_map_icon} />
        </div>{" "}
        <div className="contact_ussupport_header_su_bodyy_text">
          22, Commercial Avenue, Ikeja, Lagos
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
