import axios from "axios";

// export const baseUrl =
//   'https://y02qmvx339.execute-api.us-east-1.amazonaws.com/dev';
export const baseUrl = "https://api.petdatabase.ng/api/v1";

// export let timoutReq = 10000;
const token = localStorage.getItem("token");
export const axiosCalls = async (path, method, data = null) => {
  console.log(token);
  try {
    let res = await axios({
      method,
      url: `${baseUrl}/${path}`,
      data,
      // timeout: timoutReq,
      headers: { Authorization: `Bearer ${token}` },
    });
    if (res) {
      return res;
    }
  } catch (error) {
    if (error.message == "timeout of 100ms exceeded") {
      return { er: "slowNetwork" };
    }
    return { er: error?.response === undefined ? error : error.response };
  }
};

export const axiosFormData = async (path, method, data) => {
  const token = localStorage.getItem("token");
  try {
    let res = await axios({
      method: `${method}`,
      url: `${baseUrl}/${path}`,
      data,
      // timeout: timoutReq,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });

    if (res) {
      return res.data;
    }
  } catch (error) {
    if (error.message == "timeout of 100ms exceeded") {
      return { er: "slowNetwork" };
    }
    return { er: error.response };
  }
};
