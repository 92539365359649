import React, { useState } from "react";
import AuthWrap from "../authWrap";
import "./index.scss";
import AuthPet1 from "../../../assets/images/authPet1.png";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import ResetPasswordForm from "./reset/index";
import { hideLoader, showLoader } from "../../../components/loader";
import { Toast } from "../../../components/toast";
import { axiosCalls } from "../../../_api";
import validateEmail from "../../../components/validateEmail";
import validatePassword from "../../../components/validatePassword";

const ResetPasswordPage = () => {
  const history = useHistory();

  const [account, setAccount] = useState({
    email: "",
    password: "",
    accountType: "",
    firstName: "",
    confirmPassword: "",
  });

  const handleChange = (event) => {
    console.log(event);
    setAccount({ ...account, [event?.target?.name]: event?.target?.value });
  };

  const handleSubmit = async () => {
    const emailV = validateEmail(account.email);
    const passwordV = validatePassword(account.password);

    if (tab === 1) {
      if (emailV !== true) {
        return Toast("error", "Invalid Email");
      }

      const emailData = {
        email: account?.email,
      };
      showLoader();
      const res = await axiosCalls("auth/forgot-password", "POST", emailData);
      console.log("LOGIN RES>>>", res);

      if (res) {
        if (res?.er) {
          hideLoader();
          Toast("error", `${res?.er?.data?.message}`);
          return;
        }
        hideLoader();
        Toast("success", "Please check your email for an OTP");
        setTab(2);
      }
    }

    if (tab === 2) {
      if (account?.code === "") {
        return Toast("error", "Please input OTP sent to your email");
      }

      if (passwordV !== true) {
        return Toast("error", passwordV);
      }

      if (account?.confirmPassword !== account?.password) {
        return Toast("error", "Passwords do not match");
      }
    }

    const data = {
      code: account?.code,
      email: account?.email,
      password: account?.password,
      password_confirmation: account?.confirmPassword,
    };
    showLoader();
    const res = await axiosCalls("auth/reset-password", "POST", data);
    console.log("LOGIN RES>>>", res);

    if (res) {
      if (res?.er) {
        hideLoader();
        Toast("error", `${res?.er?.data?.message}`);
        return;
      }

      hideLoader();
    }
  };

  const [tab, setTab] = useState(1);

  useEffect(() => {
    console.log(account);
  }, [account]);

  return (
    <AuthWrap
      petImageBottom={AuthPet1}
      onChange={handleChange}
      name="accountType"
      value={account.accountType}
      handleSubmit={handleSubmit}
      header="Forgot Password?"
      subHeader="Input your email address & we’ll send you reset instructions"
      subBtnText={"Continue"}
      type="login"
      showDrop={false}
    >
      <ResetPasswordForm
        handleChange={handleChange}
        account={account}
        history={history}
        tab={tab}
        setTab={setTab}
      />
    </AuthWrap>
  );
};

export default ResetPasswordPage;
