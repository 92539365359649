import React from "react";
import "./gradientBtn.scss";
import { micro_top_arrow_btn } from "../../../assets";

const GradientBtn = ({ btnText }) => {
  return (
    <div className="gradient_two_flex_left_search___">
      <div className="gradient_sectionOne_top_flexx_left_bigbtn">{btnText}</div>
      <div className="gradient_sectionOne_top_flexx_left_bigarrow">
        <img src={micro_top_arrow_btn} />
      </div>
    </div>
  );
};

export default GradientBtn;
