import React, { useState } from "react";
import Input from "../../../../components/form/input";
import "./index.scss";
import Sizebox from "../../../../components/sizebox";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const ResetPasswordForm = ({ handleChange, account, history, tab, setTab }) => {
  const [showPass, setShowPass] = useState(false);
  const [showPassCon, setShowPassCon] = useState(false);
  return (
    <div className="petOwner">
      <Sizebox height="20px" />
      {tab === 1 ? (
        <Input
          onChange={handleChange}
          name="email"
          value={account?.email}
          placeholder={"Email"}
          type="email"
        />
      ) : null}

      {tab === 2 ? (
        <>
          <Input
            onChange={handleChange}
            name="OTP"
            value={account?.OTP}
            placeholder={"OTP"}
            type="number"
          />
          <Sizebox height="20px" />
          <Input
            onChange={handleChange}
            name="password"
            value={account?.password}
            placeholder="Password"
            type={showPass ? "text" : "password"}
            IconRightClick={() => {
              setShowPass(!showPass);
            }}
            IconRight={showPass ? Visibility : VisibilityOff}
          />

          <Sizebox height="20px" />
          <Input
            onChange={handleChange}
            name="ConfirmPassword"
            value={account?.ConfirmPassword}
            placeholder="Confirm Password"
            type={showPassCon ? "text" : "password"}
            IconRightClick={() => {
              setShowPassCon(!showPassCon);
            }}
            IconRight={showPassCon ? Visibility : VisibilityOff}
          />
        </>
      ) : null}
    </div>
  );
};

export default ResetPasswordForm;
