import React, { useState } from "react";
import AuthWrap from "../authWrap";
import "./index.scss";
import AuthPet1 from "../../../assets/images/authPet1.png";
import { useEffect } from "react";
import LoginScreen from "./loginScreen";
import { useHistory } from "react-router-dom";
import { axiosCalls } from "../../../_api";
import { Toast } from "../../../components/toast/index";
import validateEmail from "../../../components/validateEmail";
import validatePassword from "../../../components/validatePassword";
import { useDispatch } from "react-redux";
import { setUser } from "../../../reducers/auth";
import { hideLoader, showLoader } from "../../../components/loader";
const emaillNotVerified = "Email not verified";

const LoginPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [account, setAccount] = useState({
    email: "",
    password: "",
  });

  const handleChange = (event) => {
    console.log(event);
    setAccount({ ...account, [event?.target?.name]: event?.target?.value });
  };

  const handleSubmit = async () => {
    const emailV = validateEmail(account.email);
    const passwordV = validatePassword(account.password);

    for (const field in account) {
      if (account[field] === "") {
        Toast("error", `${field} is required`);
        return;
      }
    }

    if (emailV !== true) {
      return Toast("error", "Invalid Email");
    }
    if (passwordV !== true) {
      return Toast("error", passwordV);
    }
    const data = {
      email: account?.email,
      password: account?.password,
    };
    showLoader();
    const res = await axiosCalls("auth/login", "POST", data);

    if (res) {
      if (res?.er) {
        console.log(res?.er?.data?.message);

        hideLoader();
        Toast("error", `${res?.er?.data?.message}`);
        if (res?.er?.data?.message == emaillNotVerified) {
          history.push(`/verify_email/${account?.email}/login`);
        }
        return;
      }
      localStorage.setItem("token", res?.data?.access_token);
      const userInfo = {
        ...res?.data?.user,
        token: res?.data?.access_token,
        expires: res?.data?.expires_in,
        isAuth: true,
      };
      console.log(userInfo);
      dispatch(setUser(userInfo));
      hideLoader();
      // window.location.href = "/profile";
      history.push("/profile");
    }
  };

  return (
    <AuthWrap
      petImageBottom={AuthPet1}
      onChange={handleChange}
      name="accountType"
      value={account.accountType}
      handleSubmit={handleSubmit}
      header="Welcome Back!"
      subHeader="Log in to your account"
      subBtnText={"Log in to your account"}
      type="login"
      showDrop={false}
    >
      <LoginScreen
        handleChange={handleChange}
        account={account}
        history={history}
      />
    </AuthWrap>
  );
};

export default LoginPage;
