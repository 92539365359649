import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Navbar from "./navbar";
import Sidebar from "./sidebar";
import "./index.scss";
import { PersonOutlined, NoteAdd } from "@mui/icons-material";
import { useEffect } from "react";
import { userTypes } from "../../consts";
import { PetsOutlined } from "@material-ui/icons";
import {
  BellIconSidebar,
  CallIconSidebar,
  CreditCardIconSidebar,
  InfoIconSidebar,
  LogoutIconSidebar,
  NoteIconSidebar,
  PetFootIconSidebar,
  UserIcon,
  UserIconSidebar,
} from "../../assets/svg";
import useAuth from "../../hooks/useAuuth";
import { Toast } from "../toast";
import { useDispatch } from "react-redux";
import { setUser } from "../../reducers/auth";
// import EditNoteIcon from "@mui/icons-material/EditNote";

export const ProtectedLayout = ({ children }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const logout = () => {
    localStorage.clear();
    dispatch(
      setUser({
        isAuth: false,
      })
    );
    Toast("info", "Logout successfully.");
  };
  const user = useAuth();
  const [auth, setAuth] = useState(true);
  const [activeUser, setActiveUser] = useState(user?.user_type ?? "");
  const [routes, setRoutes] = useState([]);

  const [petOwnerRoutes, setPetOwnerRoutes] = useState([
    {
      name: "Owner’s Profile",
      route: `/profile`,
      Icon: <UserIconSidebar />,
    },

    {
      name: "Pet’s",
      route: `/pet_profile`,
      Icon: <PetFootIconSidebar />,
    },

    {
      name: "Annual Dues",
      route: `/annual_dues`,
      Icon: <CreditCardIconSidebar />,
    },

    {
      name: "Notifications",
      route: `/notifications`,
      Icon: <BellIconSidebar />,
    },

    {
      name: "Contact Us",
      route: `/contact_us`,
      Icon: <CallIconSidebar />,
    },

    {
      name: "Report Missing Pet",
      route: `/missing_pet`,
      Icon: <InfoIconSidebar />,
    },

    {
      name: "Logout",
      route: null,
      Icon: <LogoutIconSidebar />,
      action: logout,
    },
  ]);

  const [vetClinicRoutes, setvetClinicRoutes] = useState([
    {
      name: "Company’s Profile",
      route: `/company_profile`,
      Icon: <UserIconSidebar />,
    },
    {
      name: "Profile",
      route: `/profile`,
      Icon: <UserIconSidebar />,
    },

    {
      name: "Pet’s For Sale",
      route: `/pet_for_sale`,
      Icon: <PetFootIconSidebar />,
    },

    {
      name: "Kennel Approvals",
      route: `/kennel_approvals`,
      Icon: <CreditCardIconSidebar />,
    },

    {
      name: "Pet Approvals",
      route: `/pet_approvals`,
      Icon: <CreditCardIconSidebar />,
    },

    {
      name: "Notifications",
      route: `/notifications`,
      Icon: <BellIconSidebar />,
    },

    {
      name: "Contact Us",
      route: `/contact_us`,
      Icon: <CallIconSidebar />,
    },

    {
      name: "Logout",
      route: null,
      Icon: <LogoutIconSidebar />,
      action: logout,
    },
  ]);

  const [kennelsRoutes, setkennelsRoutes] = useState([
    {
      name: "Company’s Profile",
      route: `/company_profile`,
      Icon: <UserIconSidebar />,
    },
    {
      name: "Profile",
      route: `/profile`,
      Icon: <UserIconSidebar />,
    },

    {
      name: "Pet’s For Sale",
      route: `/pet_for_sale`,
      Icon: <PetFootIconSidebar />,
    },

    {
      name: "Pending Approvals",
      route: `/pending_approvals`,
      Icon: <CreditCardIconSidebar />,
    },

    {
      name: "Notifications",
      route: `/notifications`,
      Icon: <BellIconSidebar />,
    },

    {
      name: "Contact Us",
      route: `/contact_us`,
      Icon: <CallIconSidebar />,
    },

    {
      name: "Logout",
      route: null,
      Icon: <LogoutIconSidebar />,
      action: logout,
    },
  ]);

  const handleListClick = (list) => {
    if (list?.route == null) {
      list?.action();
    }
    history.push(list?.route);
  };

  useEffect(() => {
    if (activeUser === userTypes.petOwner) {
      setRoutes(petOwnerRoutes);
    }

    if (activeUser === userTypes.vetClinic) {
      setRoutes(vetClinicRoutes);
    }

    if (activeUser === userTypes.kennels) {
      setRoutes(kennelsRoutes);
    }
  }, [activeUser]);

  return (
    <div className="layout">
      <div className="layout__sidebar">
        <Sidebar routes={routes} handleListClick={handleListClick} />
      </div>

      <div className="children-wrap">
        <div className="layout__body ">
          <div className="layout__content">
            {user?.isAuth === true ? children : history.push(`/`)}
          </div>
        </div>
      </div>
    </div>
  );
};
