import React, { useState } from "react";
import "./sectionTwo.scss";
import { active_tab_icon } from "../../../../assets";
import Faq from "../faq/Faq";
import ContactUs from "../contactUs/ContactUs";

const SectionTwo = () => {
  const [tab, setTab] = useState(0);

  const handleTab = (status) => {
    setTab(status);
  };
  return (
    <div className="main_home_micro_sectionOne_gen_bodyy">
      <div className="main_home_micro_sectionOne_gen_bodyy__sub">
        <div className="partner_secTwo_genFlexx_body">
          <div className="partner_secTwo_genFlexx_left">
            <div
              onClick={() => {
                handleTab(0);
              }}
              className="partner_secTwo_genFlexx_left_vet"
            >
              <div
                style={{ fontWeight: tab === 0 && "700" }}
                className="partner_secTwo_genFlexx_left_vet_text"
              >
                FAQ
              </div>

              {tab === 0 && (
                <div className="partner_secTwo_genFlexx_left_vet_icon">
                  <img src={active_tab_icon} />
                </div>
              )}
            </div>
            <div
              onClick={() => {
                handleTab(1);
              }}
              className="partner_secTwo_genFlexx_left_vet"
            >
              <div
                style={{ fontWeight: tab === 1 && "700" }}
                className="partner_secTwo_genFlexx_left_vet_text"
              >
                CONTACT US
              </div>

              {tab === 1 && (
                <div className="partner_secTwo_genFlexx_left_vet_icon">
                  <img src={active_tab_icon} />
                </div>
              )}
            </div>
          </div>

          {tab === 0 && <Faq />}

          {tab === 1 && <ContactUs />}
        </div>
      </div>
    </div>
  );
};

export default SectionTwo;
