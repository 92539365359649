import React from "react";
import "./sectionTwo.scss";
import { micro_loca_icon, search_map_icon } from "../../../../assets";
import MapComponentAddress from "./mapComponentAddress/MapComponentAddress";

const SectionTwo = () => {
  return (
    <div className="main_home_sectionFour_gen_bodyy">
      <div className="main_home_sectionFour_gen_bodyy_topGradient"></div>
      <div className="main_home_sectionFour_gen_bodyy__sub">
        <div className="map_microchipping_head_find">
          Find A Vet Clinic Near You
        </div>
        <div className="map_microchipping_head_list">
          List of verified vet clinics available to microchip your pet.{" "}
        </div>
        <div className="main_microchipping_body_imageee_gen">
          <div className="main_microchipping_body_imageee_gen_two">
            <div className="micro_sectionOne_top_flexx_left_search____">
              <div className="section_two_flex_left_search_body_microchip_two">
                <div className="section_two_flex_left_search_icon_microchip_two">
                  <img src={micro_loca_icon} />
                </div>
                <input
                  type="text"
                  placeholder="Enter Location"
                  // value={searchText}
                  // onChange={handleSearchChange}
                />
              </div>

              <div className="section_two_flex_left_search_icon_ext">
                <img src={search_map_icon} />
              </div>
            </div>
            <MapComponentAddress />
            <MapComponentAddress />
            <MapComponentAddress />
            <MapComponentAddress />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionTwo;
