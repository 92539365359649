import React from "react";
import "./vetClinic.scss";
import GradientBtn from "../../gradientBtn/GradientBtn";

const VetClinic = () => {
  return (
    <div className="partner_vet_clinic_gen_body">
      <div className="partner_vet_clinic_gen_body_head">
        Vet Lorem ipsum dolor sit amet enim. Etiam ullamcorper.
      </div>
      <div className="partner_vet_clinic_gen_body_text">
        Lorem ipsum dolor sit amet enim. Etiam ullamcorper. Suspendisse a
        pellentesque dui, non felis. Maecenas malesuada elit lectus felis,
        malesuada ultricies.
      </div>
      <div className="partner_vet_clinic_gen_body_text">
        Lorem ipsum dolor sit amet enim. Etiam ullamcorper. Suspendisse a
        pellentesque dui, non felis. Maecenas malesuada elit lectus felis,
        malesuada ultricies.
      </div>

      <div style={{ marginTop: "70px" }}></div>
      <GradientBtn btnText="APPLY" />
    </div>
  );
};

export default VetClinic;
