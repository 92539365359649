import Input from "../../components/form/input";
import SelectBox from "../../components/form/select";
import { userTypes } from "../../consts";

const ProfileForm = ({ account, handleChange, type }) => {
  const loginUser = userTypes;
  return (
    <>
      {loginUser.vetClinic === type && (
        <div className="generalFormContainer">
          <div className="generalForm">
            <div className="generalFormChild">
              <Input
                label={"Company Name"}
                value={account?.company_name}
                name="company_name"
                onChange={handleChange}
              />
            </div>

            <div className="generalFormChild">
              <Input
                label={"Company’s Email Address"}
                value={account?.email}
                name="email"
                onChange={handleChange}
                // disabled={true}
              />
            </div>

            <div className="generalFormChild">
              <Input
                label={"Company’s Phone Number"}
                value={account?.company_phone_number}
                name="company_phone_number"
                onChange={handleChange}
              />
            </div>

            <div className="generalFormChild">
              <Input
                label={"Clinic Address"}
                value={account?.clinic_address}
                name="clinic_address"
                onChange={handleChange}
              />
            </div>

            <div className="generalFormChild">
              <Input
                label={"Contact Person’s Name"}
                value={account?.contact_person}
                name="contact_person"
                onChange={handleChange}
              />
            </div>

            <div className="generalFormChild">
              <Input
                label={"Contact Person’s Phone Number"}
                value={account?.contact_phone_number}
                name="contact_phone_number"
                onChange={handleChange}
              />
            </div>

            <div className="generalFormChild">
              <Input
                label={"Veterinary Council Number (VCN)"}
                value={account?.veterinary_council_number}
                name="veterinary_council_number"
                onChange={handleChange}
              />
            </div>

            <div className="generalFormChild">
              <Input
                label={"CAC"}
                value={account?.cac_number}
                name="cac_number"
                onChange={handleChange}
              />
            </div>

            {/* <div className="generalFormChild">
              <Input
                label={"Contact Person’s Phone Number"}
                value={account?.contact_person_phone}
                name="contact_person"
                onChange={handleChange}
              />
            </div> */}
          </div>
        </div>
      )}
    </>
  );
};

export default ProfileForm;
