import React, { useState } from "react";
import { useEffect } from "react";
import { Modal as ModalC } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { RemoveRedEye } from "@mui/icons-material";
import ProfileForm from "./form";
import useAuth from "../../hooks/useAuuth";
import { hideLoader, showLoader } from "../../components/loader";
import { axiosCalls } from "../../_api";
import { userTypes } from "../../consts";
import { Toast } from "../../components/toast";
import validatePassword from "../../components/validatePassword";
import ProfileImage from "../../components/profileImage";
import Sizebox from "../../components/sizebox";
import Input from "../../components/form/input";

const PetOwnerProfile = () => {
  const user = useAuth();
  const [account, setAccount] = useState({ first_name: "", last_name: "" });
  const [showModal, setShowModal] = useState(false);
  const [password, setPassword] = useState({
    password: "",
    confirmPassword: "",
  });
  const handleChange = (event) => {
    console.log(event);
    setAccount({ ...account, [event?.target?.name]: event?.target?.value });
  };

  const handlePasswordChange = (event) => {
    console.log(event);
    setPassword({ ...password, [event?.target?.name]: event?.target?.value });
  };

  const getProfile = async () => {
    let res = null;
    let type = user?.user_type;
    showLoader();

    if (type == userTypes.petOwner || userTypes.vetClinic) {
      res = await axiosCalls("user/profile", "get");
      console.log("RES>", res, "here>>>>");
    }
    hideLoader();
    if (res && res?.status === 200) {
      setAccount({ ...account, ...res?.data });
    }
  };

  const handleSubmit = async () => {
    let requiredFields = {
      first_name: "First Name",
      last_name: "Last Name",
      address: "Address",
      middle_name: "Middle Name",
      gender: "Gender",
      phone_number: "Phone Number",
    };

    for (const field in requiredFields) {
      if (!account[field]) {
        const fieldName = requiredFields[field];
        Toast("error", `${fieldName} is required`);
        return false;
      }
    }
    const basicInfo = {
      first_name: account?.first_name,
      last_name: account?.last_name,
      phone_number: account?.phone_number,
      address: account?.address,
      middle_name: account?.middle_name,
    };
    let res = null;
    let type = user?.user_type;
    showLoader();

    if (type == userTypes.petOwner || userTypes.vetClinic) {
      res = await axiosCalls("user/profile", "PUT", basicInfo);
    }

    hideLoader();
    if (res && res?.data?.user_type) {
      setAccount({ ...account, ...res?.data });
    }
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handlePasswordSubmit = async () => {
    const passwordV = validatePassword(password.password);

    if (passwordV !== true) {
      return Toast("error", passwordV);
    }

    if (password?.confirmPassword !== password?.password) {
      Toast("error", "Password does not match");
      return;
    }

    const data = {
      password: password?.password,
      passwordConfirm: password?.confirmPassword,
    };
    showLoader();
    const res = await axiosCalls("auth/login", "POST", data);
    console.log("LOGIN RES>>>", res);

    if (res) {
      if (res?.er) {
        hideLoader();
        Toast("error", `${res?.er?.data?.message}`);
        return;
      }
      Toast("success", "Password updated successfully.");
    }
  };

  useEffect(() => {
    getProfile();
  }, []);

  return (
    <>
      <div className="" style={{ width: "100%" }}>
        <div className="pageHeaderCentered">
          <h2>Owner’s Profile</h2>
        </div>
        <Sizebox height="30px" />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <ProfileImage />
        </div>

        <Sizebox height="60px" />

        <ProfileForm
          account={account}
          handleChange={handleChange}
          type={user?.user_type}
        />

        <Sizebox height="60px" />

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <button
            style={{
              fontSize: "15px",
              paddingRight: "50px",
              paddingLeft: "50px",
            }}
            onClick={handleSubmit}
          >
            Update profile
          </button>
        </div>
        <Sizebox height="30px" />

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "1px solid #E24F6D",
              borderRadius: "5px",
              padding: "10px 30px 10px 30px",
              cursor: "pointer",
            }}
            onClick={() => {
              setShowModal(true);
            }}
          >
            <p style={{ color: "#E24F6D" }}>Change Password</p>
          </div>
        </div>
      </div>

      <ModalC open={showModal} onClose={handleClose} center>
        <div className="successModal">
          <h2>Update Password</h2>

          <Input
            onChange={handlePasswordChange}
            name="password"
            value={password?.password}
            placeholder={"**********"}
            type="password"
            // IconLeft={<RemoveRedEye />}
          />

          <Sizebox height="20px" />
          <Input
            onChange={handlePasswordChange}
            name="confirmPassword"
            value={password?.confirmPassword}
            placeholder={"**********"}
            type="password"
          />

          <div className="sendRequestBtn">
            <button onClick={handlePasswordSubmit}>Update Password</button>
          </div>
        </div>
      </ModalC>
    </>
  );
};

export default PetOwnerProfile;
