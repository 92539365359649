import Input from "../../components/form/input";
import SelectBox from "../../components/form/select";
import { userTypes } from "../../consts";

const ProfileForm = ({ account, handleChange, type }) => {
  const loginUser = userTypes;
  return (
    <>
      {loginUser.vetClinic === type && (
        <div className="generalFormContainer">
          <div className="generalForm">
            <div className="generalFormChild">
              <Input
                label={"Name"}
                value={account?.name}
                name="name"
                onChange={handleChange}
              />
            </div>

            <div className="generalFormChild">
              <Input
                label={"Date Of Birth"}
                value={account?.dob}
                name="dob"
                onChange={handleChange}
                type="date"
              />
            </div>

            <div className="generalFormChild">
              <Input
                label={"Species"}
                value={account?.species}
                name="species"
                onChange={handleChange}
              />
            </div>

            <div className="generalFormChild">
              <Input
                label={"Microchip Number"}
                value={account?.microchip_number}
                name="microchip_number"
                onChange={handleChange}
              />
            </div>

            <div className="generalFormChild">
              <Input
                label={"Breed"}
                value={account?.contact_person}
                name="contact_person"
                onChange={handleChange}
              />
            </div>

            <div className="generalFormChild">
              <Input
                label={"Vaccination"}
                value={account?.vaccination}
                name="vaccination"
                onChange={handleChange}
              />
            </div>

            <div className="generalFormChild">
              <SelectBox
                label={"Sex"}
                value={account?.sex}
                name="sex"
                onChange={handleChange}
                options={[
                  { label: "Male", value: "male", key: "male" },
                  { label: "Female", value: "female", key: "Female" },
                  { label: "Others", value: "others", key: "Others" },
                ]}
              />
            </div>

            <div className="generalFormChild">
              <Input
                label={"Vaccination Date"}
                value={account?.vaccination_date}
                name="vaccination_date"
                onChange={handleChange}
                type="date"
              />
            </div>

            <div className="generalFormChild">
              <Input
                label={"Color"}
                value={account?.color}
                name="color"
                onChange={handleChange}
              />
            </div>

            <div className="generalFormChild">
              <Input
                label={"Re-Vaccination Date"}
                value={account?.next_vaccination_date}
                name="next_vaccination_date"
                onChange={handleChange}
                type="date"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProfileForm;
