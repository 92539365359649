import React, { useState } from "react";
import { useEffect } from "react";
import { Modal as ModalC } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { RemoveRedEye } from "@mui/icons-material";
import ProfileForm from "./form";
import useAuth from "../../hooks/useAuuth";
import { hideLoader, showLoader } from "../../components/loader";
import { axiosCalls } from "../../_api";
import { userTypes } from "../../consts";
import { Toast } from "../../components/toast";
import validatePassword from "../../components/validatePassword";
import ProfileImage from "../../components/profileImage";
import Sizebox from "../../components/sizebox";
import Input from "../../components/form/input";

const PetForSaleForm = () => {
  const user = useAuth();
  const [account, setAccount] = useState({ first_name: "", last_name: "" });
  const [showModal, setShowModal] = useState(false);
  const [password, setPassword] = useState({
    password: "",
    confirmPassword: "",
  });
  const handleChange = (event) => {
    console.log(event);
    setAccount({ ...account, [event?.target?.name]: event?.target?.value });
  };

  const handlePasswordChange = (event) => {
    console.log(event);
    setPassword({ ...password, [event?.target?.name]: event?.target?.value });
  };

  const getProfile = async () => {
    let res = null;
    let type = user?.user_type;
    showLoader();

    if (type == userTypes.petOwner) {
      res = await axiosCalls("user/profile", "get");
    }

    if (type == userTypes.vetClinic) {
      res = await axiosCalls("vet-clinic/profile", "get");
    }

    const companyInfo = {
      company_name: res?.data?.name,
      company_phone_number: res?.data?.company_phone_number,
      contact_person: res?.data?.contact_person,
      contact_phone_number: res?.data?.contact_phone_number,
      clinic_address: res?.data?.address,
      cac_number: res?.data?.cac_number,
      veterinary_council_number: res?.data?.council_number,
      email: res?.data?.email_address,
    };

    console.log("companyInfo>>>>", companyInfo);

    console.log(res);

    hideLoader();
    if (res && res?.status === 200) {
      if (type == userTypes.vetClinic) {
        setAccount({ ...account, ...companyInfo });
      }
    }
  };

  const handleSubmit = async () => {
    let type = user?.user_type;

    let requiredFieldsPetOwner = {
      first_name: "First Name",
      last_name: "Last Name",
      address: "Address",
      middle_name: "Middle Name",
      gender: "Gender",
      phone_number: "Phone Number",
    };

    if (type == userTypes.petOwner) {
      for (const field in requiredFieldsPetOwner) {
        if (!account[field]) {
          const fieldName = requiredFieldsPetOwner[field];
          Toast("error", `${fieldName} is required`);
          return false;
        }
      }
    }
    let data = null;
    const petOwnerInfo = {
      first_name: account?.first_name,
      last_name: account?.last_name,
      phone_number: account?.phone_number,
      address: account?.address,
      middle_name: account?.middle_name,
    };
    const companyInfo = {
      name: account?.company_name,
      phone_number: account?.company_phone_number,
      contact_person: account?.contact_person,
      contact_phone_number: account?.contact_phone_number,
      address: account?.clinic_address,
      cac_number: account?.cac_number,
      council_number: account?.veterinary_council_number,
      email: account?.email,
    };
    let res = null;
    if (type == userTypes.petOwner) {
      data = petOwnerInfo;
    }

    if (type == userTypes.vetClinic) {
      data = companyInfo;
    }
    showLoader();

    if (type == userTypes.petOwner) {
      res = await axiosCalls("user/profile", "PUT", data);
    }

    if (type == userTypes.vetClinic) {
      res = await axiosCalls("vet-clinic/profile", "PUT", data);
    }
    await getProfile();
    hideLoader();
    if (res && res?.data?.user_type) {
      setAccount({ ...account, ...res?.data });
    }
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handlePasswordSubmit = async () => {
    const passwordV = validatePassword(password.password);

    if (passwordV !== true) {
      return Toast("error", passwordV);
    }

    if (password?.confirmPassword !== password?.password) {
      Toast("error", "Password does not match");
      return;
    }

    const data = {
      password: password?.password,
      passwordConfirm: password?.confirmPassword,
    };
    showLoader();
    const res = await axiosCalls("auth/login", "POST", data);
    console.log("LOGIN RES>>>", res);

    if (res) {
      if (res?.er) {
        hideLoader();
        Toast("error", `${res?.er?.data?.message}`);
        return;
      }
      Toast("success", "Password updated successfully.");
    }
  };

  useEffect(() => {
    getProfile();
  }, []);

  useEffect(() => {
    console.log(account);
  }, [account]);

  return (
    <>
      <div className="" style={{ width: "100%" }}>
        <div className="pageHeaderCentered">
          <h2>Create Pet</h2>
        </div>
        <Sizebox height="30px" />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <ProfileImage />
        </div>

        <Sizebox height="60px" />

        <ProfileForm
          account={account}
          handleChange={handleChange}
          type={user?.user_type}
        />

        <Sizebox height="60px" />

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <button
            style={{
              fontSize: "15px",
              paddingRight: "51px",
              paddingLeft: "51px",
            }}
            onClick={handleSubmit}
          >
            SUBMIT FOR APPROVAL
          </button>
        </div>
        <Sizebox height="30px" />
      </div>
    </>
  );
};

export default PetForSaleForm;
