import React from "react";
import "./sectionFour.scss";
import {
  arrow_right_,
  dog_section_four,
  section_four_cat,
  section_four_circle,
} from "../../../../assets";

const SectionFour = () => {
  return (
    <div className="main_home_sectionFour_gen_bodyy">
      <div className="main_home_sectionFour_gen_bodyy_topGradient"></div>
      <div className="main_home_sectionFour_gen_bodyy__sub">
        <div className="main_home_sectionFour_gen_bodyfirstFlex">
          <div className="main_home_sectionFour_gen_bodyfirstFlex_left">
            <div className="main_home_sectionFour_gen_bodyfirstFlex_left_head">
              Get Your Pet Microchipped
            </div>
            <div className="main_home_sectionFour_gen_bodyfirstFlex_left_body">
              For quick and reliable identification of your pet, marking it with
              a microchip is the most stable, effective & safest option. It is
              also internationally recognized, making travelling with your pet
              much easier.{" "}
              <span>
                {" "}
                In order to identify a chipped pet, it is necessary that a
                microchip be registered in a national animal database.
              </span>
              <span>
                Start the process with our verified veterinary doctors
                specialised in chip implantation.
              </span>
            </div>
            <div className="main_home_sectionOne_gen_bodyy__substart_hebtn">
              <div className="main_home_sectionOne_gen_bodyy__substart_hetext">
                FIND A VET CLINIC
              </div>
              <div className="main_home_sectionOne_gen_bodyy__substart_hearrow">
                <img src={arrow_right_} />
              </div>
            </div>
          </div>
        </div>

        <div className="main_home_sectionFour_gen_bodyfirstFlex_right">
          <div className="main_home_sectionFour_gen_bodyfirstFlex_right_img">
            <img src={section_four_circle} />
          </div>
          <div className="main_home_sectionFour_gen_bodyfirstFlex_right_dog_">
            <img src={dog_section_four} />
          </div>
        </div>
      </div>

      <div className="main_home_sectionFour_gen_bodyfirstFlex_right_cat">
        <img src={section_four_cat} />
      </div>
    </div>
  );
};

export default SectionFour;
