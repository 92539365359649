import React from "react";
import "./sectionFive.scss";
import {
  arrow_right_,
  section_five_dogg,
  section_five_paws,
  section_five_thermo_img,
  section_four_circle,
} from "../../../../assets";

const SectionFIve = () => {
  return (
    <div className="main_home_sectionFive_gen_bodyy">
      <div className="main_home_sectionFive_gen_bodyy_topGradient"></div>
      <div className="main_home_sectionFive_gen_bodyy__sub">
        <div className="main_home_sectionFive_gen_bodyfirstFlex">
          <div className="main_home_sectionFive_gen_bodyfirstFlex_left">
            <div className="main_home_sectionFive_gen_bodyfirstFlex_left_head">
              Register As An Implanter
            </div>
            <div className="main_home_sectionFive_gen_bodyfirstFlex_left_body">
              Certified vet clinics can apply to be vetted & approved as one of
              our official chip implanters.
            </div>
            <div className="main_home_sectionOne_gen_bodyy__substart_hebtn">
              <div className="main_home_sectionOne_gen_bodyy__substart_hetext">
                LEARN MORE
              </div>
              <div className="main_home_sectionOne_gen_bodyy__substart_hearrow">
                <img src={arrow_right_} />
              </div>
            </div>
          </div>
        </div>

        <div className="main_home_sectionFive_gen_bodyfirstFlex_right">
          <div className="main_home_sectionFive_gen_bodyfirstFlex_right_img">
            <img src={section_four_circle} />
          </div>
          <div className="main_home_sectionFive_gen_bodyfirstFlex_right_dog_">
            <img src={section_five_thermo_img} />
          </div>
        </div>
      </div>
      <div className="section_five_bottom_img_ppos">
        <div className="section_five_bottom_img_ppos_paw">
          <img src={section_five_paws} />
        </div>
        <div className="section_five_bottom_img_ppos_dog">
          <img src={section_five_dogg} />
        </div>
      </div>
    </div>
  );
};

export default SectionFIve;
