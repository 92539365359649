import React from "react";
import "./index.scss";
import { CopyAll, Email, Payment, Phone } from "@mui/icons-material";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Toast } from "../../../components/toast";

const ReportMissingPet = () => {
  return (
    <div className="annualDuePageWrap">
      <div className="headerText">
        <h2>Report Missing Pet </h2>
      </div>

      <div className="reportMissingPetCard">
        <p>
          Click button below to notify admin of missing pet. <br />
          If pet is found, an email notification will be sent to your registered
          email address.
        </p>
        <a href="mailto:nfo@nationalpetdatabase.com">
          <button>Report Missing Pet</button>
        </a>
      </div>
    </div>
  );
};

export default ReportMissingPet;
