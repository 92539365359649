import React, { useState, useEffect, useRef } from "react";
import "./header.scss";
import logo from "../../../assets/images/logo.png";
import { NavLink, useLocation, Link, useHistory } from "react-router-dom";
import Burger from "../mobileNav/Burger";
import { useOnHoverOutside } from "../useOnHoverOutside/useOnHoverOutside";
const Header = () => {
  const history = useHistory();
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const { pathname } = location;

  const splitLocation = pathname.split("/");

  const controlNavbar = () => {
    if (window.scrollY > 70) {
      setShow(true);
    } else {
      setShow(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);
    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, []);

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", changeWidth);

    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, []);

  const dropdownRef = useRef(null); // Create a reference for dropdown container
  const [isMenuDropDownOpen, setMenuDropDownOpen] = useState(false);

  // Function to close dropdown
  const closeHoverMenu = () => {
    setMenuDropDownOpen(false);
  };
  const openHoverMenu = () => {
    setMenuDropDownOpen(true);
  };
  useOnHoverOutside(dropdownRef, closeHoverMenu); // Call the hook

  const getActiveClass = (path) => {
    return location.pathname === path ? "active-link" : "";
  };
  return (
    <div>
      {screenWidth < 992 ? (
        <div className="mobilenavbar-optMenuFam">
          <Link
            to="/"
            className="mobilenavbar-optMenulogo"
            style={{ textDecoration: "none" }}
          >
            <img style={{ width: "120px" }} src={logo} />
          </Link>
          <div>
            <Burger />
          </div>
        </div>
      ) : (
        <div
          style={{
            background: location.pathname === "/" ? "#EFC356" : "#fff",
          }}
          className={show ? "mainHeaderOpt_GenMaxScroll" : "header_gen_bodyy"}
        >
          <div
            className={
              show ? "mainHeaderOpt_GenScroll" : "header_gen_bodyy__sub"
            }
          >
            <ul>
              <div className="header_gen_bodyy_left_flexx_logo">
                <img style={{ width: "120px" }} src={logo} alt="Logo" />
              </div>
              <li className={getActiveClass("/")}>
                <NavLink
                  style={{
                    fontWeight: location.pathname === "/" ? "700" : "",
                    // color: location.pathname === "/" ? "#fff" : "",
                  }}
                  exact
                  to="/"
                >
                  HOME
                </NavLink>
              </li>
              <li className={getActiveClass("/microchipping")}>
                <NavLink
                  style={{
                    fontWeight:
                      location.pathname === "/microchipping" ? "700" : "",
                    // color: location.pathname === "/" ? "#fff" : "",
                  }}
                  to="/microchipping"
                >
                  MICROCHIPPING
                </NavLink>
              </li>
              <li className={getActiveClass("/partner_with_us")}>
                <NavLink
                  style={{
                    fontWeight:
                      location.pathname === "/partner_with_us" ? "700" : "",
                    // color: location.pathname === "/" ? "#fff" : "",
                  }}
                  to="/partner_with_us"
                >
                  PARTNER WITH US
                </NavLink>
              </li>
              <li className={getActiveClass("/missing_pets")}>
                <NavLink
                  style={{
                    fontWeight:
                      location.pathname === "/missing_pets" ? "700" : "",
                    // color: location.pathname === "/" ? "#fff" : "",
                  }}
                  to="/missing_pets"
                  activeClassName="active-link"
                >
                  MISSING PETS
                </NavLink>
              </li>
              <li className={getActiveClass("/support")}>
                <NavLink
                  style={{
                    fontWeight: location.pathname === "/support" ? "700" : "",
                    // color: location.pathname === "/" ? "#fff" : "",
                  }}
                  to="/support"
                  activeClassName="active-link"
                >
                  SUPPORT
                </NavLink>
              </li>
              <li className={getActiveClass("/blog")}>
                <NavLink
                  style={{
                    fontWeight: location.pathname === "/blog" ? "700" : "",
                    // color: location.pathname === "/" ? "#fff" : "",
                  }}
                  to="/blog"
                  activeClassName="active-link"
                >
                  BLOG
                </NavLink>
              </li>
            </ul>
            <div className="auth-buttons">
              <button
                className="login-btn"
                onClick={() => {
                  history.push("/login");
                }}
              >
                LOGIN
              </button>
              <button
                onClick={() => {
                  history.push("/create_account");
                }}
                className="register-btn"
              >
                REGISTER
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
