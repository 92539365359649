import React from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import {
  AdminPage,
  DownloadAudio,
  HomePage,
  ShipmentPage,
  ViewShipmentPage,
  PrayerProjectCategories,
  RolesPage,
  PetOwnerProfilePage,
  PetProfileTablePage,
  VaccinationPage,
  NotificationPage,
  ContactUsPage,
  AnnualDuesPage,
  ReportMissingPetPage,
  MainHomePage,
  MicrochippingPage,
  PartnerWithUsPage,
  MissingPetsPage,
  SupportPage,
  BlogPage,
  VerifyEmailPage,
  CompanyProfilePage,
  PetForSalePage,
  PetForSaleFormPage,
} from "./pages";
import PetOwnerAccount from "./pages/auth/createAccount/petOwner";
import CreateAccount from "./pages/auth/createAccount/createAccount";
import LoginPage from "./pages/auth/login/login";
import ResetPasswordPage from "./pages/auth/resetPass/reset";
import { userTypes } from "./consts";

export default function Routes() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={MainHomePage} />
        <Route exact path="/microchipping" component={MicrochippingPage} />
        <Route exact path="/partner_with_us" component={PartnerWithUsPage} />
        <Route exact path="/missing_pets" component={MissingPetsPage} />
        <Route exact path="/support" component={SupportPage} />
        <Route exact path="/blog" component={BlogPage} />
        <Route exact path="/create_account" component={CreateAccount} />
        <Route
          exact
          path="/auth_reset_password"
          component={ResetPasswordPage}
        />

        {/* PET OWNER PROFILE ROUTES  */}

        <Route exact path={`/profile`} component={PetOwnerProfilePage} />
        <Route exact path={`/company_profile`} component={CompanyProfilePage} />
        <Route exact path={`/pet_profile`} component={PetProfileTablePage} />
        <Route exact path={"/vaccination/:id"} component={VaccinationPage} />
        <Route exact path={"/notifications"} component={NotificationPage} />
        <Route exact path={"/contact_us"} component={ContactUsPage} />
        <Route exact path={"/annual_dues"} component={AnnualDuesPage} />
        <Route exact path={"/missing_pet"} component={ReportMissingPetPage} />
        <Route exact path={`/pet_for_sale`} component={PetForSalePage} />
        <Route
          exact
          path={`/pet_for_sale/:id`}
          component={PetForSaleFormPage}
        />
        {/* PET OWNER PROFILE ROUTES END */}
        <Route exact path="/login" component={LoginPage} />
        <Route
          exact
          path={"/verify_email/:email/:from"}
          component={VerifyEmailPage}
        />
      </Switch>
    </Router>
  );
}
