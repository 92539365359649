import React from "react";
import "./sectionOne.scss";
import {
  active_tab_icon,
  missing_pet_gradient,
  support_gradient_home,
} from "../../../assets";
import Faq from "./faq/Faq";
import ContactUs from "./contactUs/ContactUs";

const SectionOne = () => {
  return (
    <div className="main_home_support_sectionOne_gen_bodyy">
      <div className="main_home_support_sectionOne_gen_bodyy__sub">
        <div className="micro_sectionOne_top_flexxBody">
          <div className="micro_sectionOne_top_flexxImg">
            <img src={support_gradient_home} />
          </div>
          <div className="support_sectionOne_top_flexxtext">
            Have any questions? We’re here to help.
          </div>
          <div className="micro_sectionOne_top_flexxAbsolute">
            CUSTOMER
            <span style={{ color: "#EEC355", marginLeft: "15px" }}>
              SUPPORT{" "}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionOne;
